import { Text, VStack } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { BiBitcoin } from "react-icons/bi";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import glovar from './glovar';
import { getMONEDADivisa  } from './glovar'
//import eventBus from "./eventBus";
import { FiUser, FiSettings, FiSearch } from "react-icons/fi";
import eventBus from "../eventBus";
import i18n from "../../i18n"
import {useNavigate} from 'react-router-dom'

var contadorTraders=0;

//try { EventGA("carroTopTrad","carroTopTrad_"+glovar.USER_LANG_PRO1, toptrader.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
//try { EventGA("carroTopTrad","carroTopTrad_"+symbol+"to "+toptrader.name, toptrader.name); } catch (exx) { /*console.log("petada event:"+exx)*/}


function changeMenu(symbol,name,mobile) {

  //if(mobile==1) 
  if(1==2)
  {
    //try { EventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/') ;
  }
  else 
  {
  var toptraderPos=0;
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")

  for(let j=0;glovar.TOPTRADERSLIST.length;j++) {
    if(glovar.TOPTRADERSLIST[j].name==name) {
      toptraderPos=j;
      break;
    }
  }
  }

  eventBus.dispatch("showTopTraderfromCarr", { sym:symbol, tt: glovar.TOPTRADERSLIST[toptraderPos] });
  //console.log('commmm pos:'+pos)
  //console.log(glovar.TOPTRADERSLIST[toptraderPos])
  } 

function comm(pos) {
  //glovar.CURRENT_MENU_ID=3;
  //eventBus.dispatch("showTopTrader", { pos: (pos-1) });
  //console.log('commmm pos:'+pos)

  } 


const Wallet = ({
  icon,
  title,
  subtitle,
  percent,
  followers,
  change,
  loss,
  color1,
  color2,
  pos
}) => (
  <Box mr={1} mt={0} mb={3} _hover={{ cursor: "pointer" }}>
    <div onClick= {() =>  changeMenu(pos)}>
    <Flex alignItems="center">

    <Box ml={0} w={"2pc"}>
        <Heading fontSize="md">{pos}</Heading>
        
      </Box>



           <Box ml={0} w={"18pc"}>
        <Heading fontSize="md">{title}</Heading>
        <Text fontSize="12" fontWeight="normal">
            {subtitle}
          </Text>
      </Box>
      
      <Text
          fontSize="12"
          textColor={loss ? "red" : "black"}
          fontWeight="normal"
          w={"4pc"}
        >
          {change}
        </Text>
      <Box
        borderRadius="lg"
        fontSize="14"
        fontWeight="bold"
        textAlign="center"
        w={"5pc"}
        p={1}
        bg={color1}
        textColor={color2}
      >
        {percent}
      </Box>
      <Spacer />
      
     
        <Text fontSize="14" fontWeight="bold" mr={1}>
          {followers}
        </Text>
        <FiUser/>
     
    </Flex>
    </div>
  </Box>
);


function Item(props) {
  //console.log(props.value)



  //if(props.value.idCartera) return <li>{props.value.idCartera}</li>;
  //else return null;

  //if(props.value.stock=="Micron") { return null; }
  //if(props.value.stock=="Citigroup") { return null; }
  //if(props.value.stock=="Verisign") { return null; }
  //if(props.value.stock=="Qualcomm") { return null; }


  
  var moneda=props.value.moneda;
  //contadorTraders=contadorTraders+0.5;

  try { 
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    contadorTraders=contadorTraders+0.5;
    //console.log("developmeeeeent")
    //console.log("NODE_ENV:"+process.env.NODE_ENV)
   } else {
    contadorTraders=contadorTraders+1;
    // production code
    //console.log("producttiooon")
   }
  }
  catch(Exx) { contadorTraders=contadorTraders+1; }

  //try { moneda=getMONEDADivisa(props.value.currency)} catch (Exxx) { console.log("petada moneda:"+Exxx)}

  //if(props.value.regularMarketChange>0) 
  {
   
    return (
<Wallet
          //title="TEF.MC"
          //title={glovar.TOPTRADERS_PRO1[glovar.TOPTRADERS_PRO1.length-2].idCartera}
          //title={props.value.idCartera}
          pos={contadorTraders}
          title={props.value.name}
          subtitle={props.value.symbol}
          icon={<FiArrowUp />}
          change={"+"+Number(props.value.profit).toFixed(0)+moneda}
          percent={Number(props.value.rentab).toFixed(2)+"%"}
          followers={props.value.followers}
          //loss
          color1="#1FB31F"
          color2="#FFFFFF"
        />
    );
  }

}


/*function MyList() {

  return (
   <ul>
     
       {glovar.TOPTRADERSLIST.map((item) => <Item key={item.id} value={item} />)}

     
   </ul>
 );
}*/

function MyFavoritesList() {

  contadorTraders=0;

  

  if(!glovar.TOPTRADERSLIST)
  {
    return null;
  }
  {
  for (let i=0; i<glovar.TOPTRADERSLIST.length;i++)
  {
    return (
      glovar.TOPTRADERSLIST.map((item) => <Item key={item.id} value={item} />)
    );

  }
}
}

export default ({symbol, mobile}) => {

  //return(null); 

  var USE_NAVIGATE=0;
  var MOBILE_BROWSER=0;

  try { if(mobile>0) { MOBILE_BROWSER=1; } } catch(Exx) {}

  try { var navigate = useNavigate(); USE_NAVIGATE=1; } catch(Exxx) { USE_NAVIGATE=0; }

 

  try {
    var llistatToptraders=[]

  

    if(!glovar.TOPTRADERSLIST)
    {
      return null;
    }
    
    for (let i=0; i<(glovar.TOPTRADERSLIST.length || 10);i++) {

      var SYMBOL_TOPTRADER=0; 
      //console.log("toptrader:"+i)
      var todoPortfolio=JSON.parse("["+glovar.TOPTRADERSLIST[i].cartera+"]")

      for(let j=0; j<todoPortfolio.length; j++) 
      {
       if(todoPortfolio[j].symbol==symbol) {
        SYMBOL_TOPTRADER=1;
        break;
       }
      //console.log(todoPortfolio[0]);
      }

      //Si no trobem a cartera, busquem a historial
      if(SYMBOL_TOPTRADER==0) {
        var todoHistorial=JSON.parse("["+glovar.TOPTRADERSLIST[i].historial+"]")

        for(let j=0; j<todoHistorial.length; j++) 
        {
         if(todoHistorial[j].symbol==symbol) {
          SYMBOL_TOPTRADER=1;
          break;
         }
        //console.log(todoPortfolio[0]);
        }
      }

      if(SYMBOL_TOPTRADER==1) {
       llistatToptraders.push(glovar.TOPTRADERSLIST[i]);
      }
    }
    
    //for (let i=0; i<glovar.TOPTRADERSLIST.length;i++)
    //for (let i=0; i<5;i++)
    {
     
      if(llistatToptraders.length==0) {
        return(
          <VStack mt={-2} mb={5}>
          <Text textColor="black" fontSize="14" fontWeight="normal" >{i18n.t("toptraders_tenen")}  <b>{symbol}</b> {i18n.t("en_cartera_histo")}</Text>
          <Box marginRight={2}   _hover={{ cursor: "pointer" }}  
         flexDirection="row"
         rounded={9} background="black" textColor="white" fontWeight="semibold" fontSize={14}
         onClick= {() => { 
          //window.location.replace('/');
          //changeMenu(symbol,toptrader.name)
          //glovar.CURRENT_MENU_ID=1;
          //if( USE_NAVIGATE==1) { navigate('/') }

          
          //changemenu(1)
           //if(MOBILE_BROWSER==1) 
           if(1==2)
           {
            //navigate('/') 
            changeMenu("symbol","toptrader.name",MOBILE_BROWSER);
          }
          else {
          eventBus.dispatch("gotoTopTraders", {});
          if( USE_NAVIGATE==1) { navigate('/home') }
          }
          //gotoSymbol(props)
          //window.location.replace('/symbol/'+toptrader.name) 
          //try { EventGA("carroTopTrad","carroTopTrad_"+glovar.USER_LANG_PRO1, toptrader.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
          //try { EventGA("carroTopTrad","carroTopTrad_"+symbol+"to "+toptrader.name, toptrader.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
        }
         }
         > 
          <Box  rounded={6}  m="3px" background="white" textColor="black" textAlign="center">
          <Text p={1.5} pl={6} pr={6}  fontSize={14} >{i18n.t("consul_toptraders")}</Text>
          
            </Box>
          
            </Box>
            </VStack>
        );
      }
      else if(llistatToptraders.length>0) {

        var styleclass="row container_carr_totpraders";

        if(MOBILE_BROWSER) {
          styleclass="row mb_container_carr_totpraders";
        }

      return(
        <VStack mt={0}>
      <Text textColor="black" fontSize="14" fontWeight="normal" >{i18n.t("toptraders_tenen")} <b>{symbol}</b> {i18n.t("en_cartera_histo")}</Text>
      <div class={styleclass}>
        <Flex background="white" flexDirection="row" mt={0.5} mb={1.5} >
        {llistatToptraders.filter((toptrader, idx) => idx <20).map((toptrader) => 
         
         <Box marginRight={2} pt="1px"  _hover={{ cursor: "pointer" }}  
         flexDirection="row"
         rounded={6} background="black" textColor="white" fontWeight="semibold" fontSize={14}
         onClick= {() => { 
          //window.location.replace('/');
          changeMenu(symbol,toptrader.name,MOBILE_BROWSER)
          if( USE_NAVIGATE==1) { navigate('/home') }
          //gotoSymbol(props)
          //window.location.replace('/symbol/'+toptrader.name) 
          //try { EventGA("carroTopTrad","carroTopTrad_"+glovar.USER_LANG_PRO1, toptrader.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
          //try { EventGA("carroTopTrad","carroTopTrad_"+symbol+"to "+toptrader.name, toptrader.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
        }
         }
         > 
          <Box>
          <Text pt={0} pr={1.5} pl={1.5} pb="1px" >{toptrader.name}</Text>
          <Box  background="#67B634" rounded={6} pb={1}  textAlign="center" >
          <Text background="#67B634" mt="1px" textColor="white"> +{toptrader.rentab}%</Text>
          </Box>
            </Box>
          
            </Box>
         
         )}
        </Flex>
        </div>
        </VStack>
      );
     }
    }
  
  }
  catch(Exx) { return null}
};
