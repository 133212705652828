import "./ToptraderList_styles.scss";
import { useState, useRef } from "react";
import ShowAssets from "./ShowAssets";

import './search.css';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  //Select,
} from '@chakra-ui/react'

import Select from 'react-select';

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import Papa from "papaparse"


import { Heading } from "@chakra-ui/layout";
//import { Grid } from "@chakra-ui/layout";
//import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { FiPlus,FiEdit,FiCopy,FiTrash2 } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
import {Container } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel, Switch, Checkbox,Input } from '@chakra-ui/react'
import { FiBell, FiSettings, FiSearch, FiLogOut, FiLogIn, FiMessageSquare, FiHeart } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'



import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";


import i18n from "../../i18n"

import glovar from './glovar';

//import glovar from './components/content/glovar';

//import glovar from "./glovar";
//import gsap from "gsap";

import emailjs from "@emailjs/browser"
import eventBus from "../eventBus";


import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';

export default ({fitxaDesk,forceBackground, onePage}) => {


  var FITXA_DESK=false;
  var COL_START=3;
  var COL_END=13;
  var TEXT_NEWS= "Do you want to know which assets have an interesting technical scenario in the short/medium term? <br />Subscribe to our newsletter, <b>it's free!</b>";

  var P_SIMPLE_GRID=2;
  var P_VSTACK=5;
  var PT_VSTACK=8;
  var PB_VSTACK=10;

  var ONE_PAGE=false;
  


  try {
    if(fitxaDesk==1) {
      FITXA_DESK=true;
      COL_START=1;
      COL_END=15;
      P_SIMPLE_GRID=0;

      P_VSTACK=0;
      PT_VSTACK=5;
      PB_VSTACK=8;
    }
    if(onePage) {
      ONE_PAGE=true;
      P_SIMPLE_GRID=20;
    }
  }
  catch(Ecc) {}
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCriteri, setSelectedCriteri] = useState(null);

  const [showSearch,setShowSearch] = useState(false);
  const [doSearch,setDoSearch] = useState(false);
  const [doRefresh,setDoRefresh] = useState(false);

  
  const [showThanksSubs,setshowThanksSubs] = useState(false);
  const [showErrorSubs,setshowErrorSubs] = useState(false);
  const [email,setEmail] = useState("");

  const [value, setValue] = useState('')
  const handleChange = (event) => setValue(event.target.value)


  var templateParams = {
    email_subscriber: email,
};


  var RANDOM_ID=Math.floor(Math.random() * 99999);
  var BUTTON_SUBS_ID="buttonSubs"+RANDOM_ID;

  const sendAnalytics = (device,screen,message) => {

    var dataInfo=device+"_"+screen+"_"+message;
    eventBus.dispatch("btnNewsLT", { data: dataInfo  });
  }

  

  const sendEmail = (e,idButton,device,screen) => {

    var SENDED_OK=false;
    e.preventDefault();

    try {
    if(email.length>10 && email.includes("@") && email.includes(".")) {

     

     templateParams.email_subscriber=email;
     emailjs.send(glovar.REF_EMAIL_SERVICE, glovar.REF_EMAIL_TEMPLATE, templateParams, glovar.REF_EMAIL_KEY)
     .then(function(response) 
     {
       //console.log('SUCCESS!', response.status, response.text);
       SENDED_OK=true;
       setEmail("")
       setshowThanksSubs(true);
       sendAnalytics(device,screen,"MailOK");
       //try { ReactGA.send({ hitType: "pageview", page: "NEWSLETTER_" + email }); } catch(Exx) {}

       //Se ha enviado correctamente
       if(idButton) {
       document.getElementById(idButton).disabled = false;
       document.getElementById(idButton).style.background = "#000000";
       }

     }, function(error) {
       //console.log('FAILED...', error);
       SENDED_OK=false;
     });
    }
    else {
      sendAnalytics(device,screen,"MailError");
      setshowErrorSubs(true);
    }

    }
    catch(Exxx) {
      sendAnalytics(device,screen,"MailCrash");
      setshowErrorSubs(true);
    }
    /*finally {
      if(SENDED_OK==true) {
        setEmail("")
        setshowThanksSubs(true)
      }
      else {
        //setshowThanksSubs(true)
      }
      //document.getElementById("demo").value = "";
    }*/
  };




  //if(!isFetchingTopTraders) 
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return(
      <Box   width= "100%" pb={"0px"}>  

      {(ONE_PAGE==1)&&
      <Box mb="20%">
      <HeaderMenu showSearch={true}/>
    </Box>
    }   

    <SimpleGrid   columns={14} gap={0} p={0} m={2} mt={0} mb={0}  paddingBottom={0} marginBottom={50}
    justifySelf="center" rounded={10} height="maxHeight">

   
       <GridItem colSpan={14} rounded={10} justifyItems="center"  backgroundColor={glovar.BACKGROUND_POPUP_NEWSLETTER_MOBILE} p={2} m={0} mt={0} paddingBottom={0}>
      
       <VStack width="100%" flexDirection="column" justifyContent="center" mt={0}>
       
           <Text textAlign="center" fontSize="30"  p={3} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("busca_tu_prox")}
           </Text>
           <Text textAlign="center" mt={0} fontSize="18" textColor="black"  pl={2} pr={2} fontFamily="Tahoma" fontWeight="normal" > 
           Do you want to know which assets have an interesting technical scenario in the short/medium term? <br />Subscribe to our newsletter, <b>it's free!</b>
           </Text>
         </VStack>
         <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={8} pb={10} alignContent="center">
        

            <Input
      placeholder={i18n.t("intro_email")}
       focusBorderColor='gray.400'
       borderColor='gray.400'
       backgroundColor='white'
      mt='0px'
      marginRight='1pc'
      marginBottom='14px'
      type="search"
      textAlign="center"
     //width='30pc'
     value={email}
      //onChange={HandleChange}
      onChange={(e) => { 
        document.getElementById(BUTTON_SUBS_ID).disabled = false;
        document.getElementById(BUTTON_SUBS_ID).style.background = "#000000";
        setEmail(e.target.value)
        setshowThanksSubs(false)
        setshowErrorSubs(false)
       }
      }
    /> 
            
            <button className="formSignInButton" id={BUTTON_SUBS_ID} marginTop={15} _hover={{ cursor: "pointer" }} 
          onClick= {(e) =>
           {
            document.getElementById(BUTTON_SUBS_ID).disabled = true;
            document.getElementById(BUTTON_SUBS_ID).style.background='#AAAAAA';
            sendEmail(e,BUTTON_SUBS_ID,"Mobile",FITXA_DESK ? "Fitxa" : "Home");
           
            
            //console.log("buscar!!")
            //setEmail("")
            //setshowThanksSubs(true)
           }
           }
           style={{ paddingLeft:20, paddingRight:20,  marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
           {i18n.t("subs_nomayus")}</button>
            </VStack>
            {showThanksSubs&&<Text textAlign="center" fontSize="16" mt={-6} textColor="green" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("gracias_news")}
           </Text>}
           {showErrorSubs&&<Text textAlign="center" fontSize="16" mt={-6} textColor="red" fontFamily="Tahoma" fontWeight="normal" > 
           {i18n.t("error_news")}
           </Text>}
            
        </GridItem>
       



  </SimpleGrid>


  {(ONE_PAGE==1)&&
      <Box  mt="40%">
      <FooterMenu/>
    </Box>
    }  


     




   </Box> 
    );
  }
  else  
  {
  return (
   
    

    <Box backgroundColor={forceBackground === 1 ? {forceBackground} : "#FFFFFF"}  width= "100%" pb={"0px"}>     

{(ONE_PAGE==1)&&
      <Box>
      <HeaderMenu showSearch={true}/>
    </Box>
    }

    <SimpleGrid   columns={14} gap={1} p={P_SIMPLE_GRID} m={0} mt={0} mb={0} justifySelf="center" rounded={10} height="maxHeight" backgroundColor="#FFFFFF">

   
       <GridItem colStart={COL_START} colEnd={COL_END} rounded={10} justifyItems="center"  backgroundColor={glovar.BACKGROUND_POPUP_NEWSLETTER}>
      
       <VStack width="100%" flexDirection="column" justifyContent="center" mt={2}>
       
       {FITXA_DESK==false&&<Text textAlign="center" fontSize="32"  p={3} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("busca_tu_prox")}
           </Text>}
           {FITXA_DESK==true&&<Text textAlign="center" fontSize="30"  p={1} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("busca_tu_prox")}
           </Text>}
           {FITXA_DESK==false&&<Text textAlign="center" mt={0} fontSize="20" textColor="black"  pl={10} pr={10} fontFamily="Tahoma" fontWeight="normal" > 
           Do you want to know which assets have an interesting technical scenario in the short/medium term? <br />Subscribe to our newsletter, <b>it's free!</b>
           </Text>}
           {FITXA_DESK==true&&<Text textAlign="center" fontSize="18" textColor="black"  pl={2} pr={2} fontFamily="Tahoma" fontWeight="normal" > 
           Do you want to know which assets have an interesting technical scenario in the short/medium term? Subscribe to our newsletter, <b>it's free!</b>
           </Text>}
         </VStack>
         <VStack width="100%" flexDirection="row" justifyContent="center" p={P_VSTACK} pt={PT_VSTACK} pb={PB_VSTACK} alignContent="center">
      
            <Input
      placeholder={i18n.t("intro_email")}
       focusBorderColor='gray.400'
       borderColor='gray.400'
       backgroundColor='white'
      mt='10px'
      marginRight='1pc'
      type="search"
     width='30pc'
     value={email}
      //onChange={HandleChange}
      onChange={(e) => { 
        document.getElementById(BUTTON_SUBS_ID).disabled = false;
        document.getElementById(BUTTON_SUBS_ID).style.background = "#000000";
        setEmail(e.target.value)
        setshowThanksSubs(false)
        setshowErrorSubs(false)
       }
      }
    /> 
            
            <button className="formSignInButton" id={BUTTON_SUBS_ID} marginTop={5} _hover={{ cursor: "pointer" }} 
          onClick= {(e) =>
           {
            document.getElementById(BUTTON_SUBS_ID).disabled = true;
            document.getElementById(BUTTON_SUBS_ID).style.background='#AAAAAA';
            sendEmail(e,BUTTON_SUBS_ID,"Desk",FITXA_DESK ? "Fitxa" : "Home");
            
            //console.log("buscar!!")
            //setEmail("")
            //setshowThanksSubs(true)
           }
           }
           style={{ paddingLeft:20, paddingRight:20,  marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
           {i18n.t("subs_nomayus")}</button>
            </VStack>
            {showThanksSubs&&<Text textAlign="center" fontSize="16" mt={-6} textColor="green" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("gracias_news")}
           </Text>}
           {showErrorSubs&&<Text textAlign="center" fontSize="16" mt={-6} textColor="red" fontFamily="Tahoma" fontWeight="normal" > 
           {i18n.t("error_news")}
           </Text>}
            
        </GridItem>
       



  </SimpleGrid>


  
  {(ONE_PAGE==181283123)&& //bottom fixed
  <Box position="fixed"
  bottom="0"
  left="0"
  width="100%">
  <FooterMenu/>
  </Box>}

  {(ONE_PAGE==1)&&
  <Box mt="20%">
  <FooterMenu/>
  </Box>}
     




   </Box> 
 
  );
}
}
