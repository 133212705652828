import {useState, useEffect} from 'react'
import glovar from '../content/glovar';
import { timeParse } from "d3-time-format";

import Papa from "papaparse"
import { getModelUser } from  './utils';

export const useFetchTodoBT = (TICKER, ItemBUY,ConditionBUY,ItemSELL,ConditionSELL,BacktestTIME,sesis1Buy,sesis1Sell,BEST_BACKTESTING,
    MAXdaysPerStrategy,
    MINnumStrategies,
    MAX_LIMIT_SESSIONS,
    MAX_LIMIT_SESIONS_CBUY,
    MAX_LIMIT_SESIONS_CSELL,
    BEST_CONDITION,
    PARAM_CONDITION
) => {

  const data = [
    // Aquí puedes agregar tus datos ficticios (20 filas) simulando el comportamiento de las acciones de Apple
    // Cada objeto debe tener las propiedades correspondientes a las columnas (Fecha inicio, Nivel inicio, etc.)
    // Ejemplo:
    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-01',
      nivelInicio: 150,
      fechaFinal: '2023-01-10',
      nivelFinal: 170,
      dias: 10,
      drawdownMax: -5,
      rentabilidadDia: 1.5,
      rentabilidadTotal: 10,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: -4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: -6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-02-08',
      nivelFinal: 175,
      dias: 38,
      drawdownMax: -43.26,
      rentabilidadDia: -1.3,
      rentabilidadTotal: -12.7,
    },
    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: -4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: -6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },

    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-01',
      nivelInicio: 150,
      fechaFinal: '2023-01-10',
      nivelFinal: 170,
      dias: 10,
      drawdownMax: 5,
      rentabilidadDia: 1.5,
      rentabilidadTotal: 10,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: 4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: 6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-02-08',
      nivelFinal: 175,
      dias: 38,
      drawdownMax: 43,
      rentabilidadDia: -1.3,
      rentabilidadTotal: -12.7,
    },
    { fechaInicio: '2023-01-01', nivelInicio: 150, fechaFinal: '2023-11-12', nivelFinal: 170, dias: 10, drawdownMax: 5, rentabilidadDia: 1, rentabilidadTotal: 10 },
    {
      fechaInicio: '2023-01-02',
      nivelInicio: 160,
      fechaFinal: '2023-01-08',
      nivelFinal: 175,
      dias: 8,
      drawdownMax: 4,
      rentabilidadDia: -0.5,
      rentabilidadTotal: -2,
    },
    // ... más filas de datos ...
    {
      fechaInicio: '2023-01-20',
      nivelInicio: 180,
      fechaFinal: '2023-02-01',
      nivelFinal: 200,
      dias: 12,
      drawdownMax: 6,
      rentabilidadDia: 2,
      rentabilidadTotal: 15,
    },
    // ... más filas de datos ...
    // ... más filas de datos ...
  ];


   const [todoHistory, setTodo] = useState([]);
   const [isFetchingHistory, setIsFetching] = useState(true);
 
   const [todoData, setTodoData] = useState([]);
   const [isFetchingData, setIsFetchingData] = useState(true);
  
   const [todobestBTPROP, setbestBTPROP] = useState([]);

  useEffect(() => {


    {
     let headers = new Headers(); 
     var URL_BACK_TESTING=glovar.URL_FETCH_BT;
     headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
     headers.set('Parameters',btoa(TICKER));


     

     
  
    
    //TEMPS
    //BEST_CONDITION=1;
     
     if(BEST_BACKTESTING==1) {
         URL_BACK_TESTING=glovar.URL_FETCH_BEST_BT;
         headers.set('Param2',btoa(""+ItemBUY+","+ConditionBUY+","+ItemSELL+","+ConditionSELL+","+BacktestTIME+","+sesis1Buy+","+sesis1Sell+","
          +MAXdaysPerStrategy+","+MINnumStrategies+","+MAX_LIMIT_SESSIONS+","+MAX_LIMIT_SESIONS_CBUY+","+MAX_LIMIT_SESIONS_CSELL+","+BEST_CONDITION+","+PARAM_CONDITION));       
     }
     else {
      headers.set('Param2',btoa(""+ItemBUY+","+ConditionBUY+","+ItemSELL+","+ConditionSELL+","+BacktestTIME+","+sesis1Buy+","+sesis1Sell));
     }
  
     //fetch(glovar.URL_FETCH_BT,{ method:'GET', //mode: 'no-cors',
     fetch(URL_BACK_TESTING,{ method:'GET', //mode: 'no-cors',
     headers: headers,
     })
    .then(res => res.json())
    .then(res => {
       //console.log("res:"+res)
      if(res=="auth/user-not-found" || res=="auth/wrong-password") {
           return;
      }
  
      if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
        return;
      }
  
      if(BEST_BACKTESTING==1) {
        setTodoData(res.bestDataBT);
        //console.log("USEFETCH:"+JSON.stringify(res.bestBTPROP))
        setbestBTPROP(res.bestBTPROP);
      }
      else {
      setTodoData(res)
      }//
      //En lugar de la coti solo queremos la ristra de datos del BT
      //setTodoData(data);

    })
    .catch((e) => {
      //console.log(e);
     })
     .finally(() => {
      setIsFetchingData(false);
      //console.log("leido data chart!!")
      const parseDate = timeParse("%Y-%m-%d");
      const CHART_COTIS=[];
  
      const link = glovar.LINK_PROXY_COTIS_DOWNLOAD+TICKER+
      //"?period1=1639992913&period2=1695615313&interval=1d&events=history"
      //"?period1=1533992913&period2=1995615313&interval=1d&events=history"
      "?period1="+glovar.CHART_PERIOD1+"&period2="+glovar.CHART_PERIOD2+"&interval=1d&events=history"
      
      
      try { 
        
      Papa.parse(link, {
        download: true,
        header: true,
            skipEmptyLines: true,
        
        step: function(row) {
          var rowx = {
            date:"",
          open:"",
          high:"",
          low:"",
          close:"",
          volume:"",
          split:"",
          dividend:"",
          absoluteChange:"",
          percentChange:""
          }
        
          rowx.date=row.data.Date//+"T23:00:00.000Z";
          rowx.date=parseDate(rowx.date);

          //console.log( rowx.date)
          //let hours = rowx.date.getHours()
          //rowx.date.setHours(2 + hours)
          //console.log(rowx.date)

          rowx.open=+row.data.Open;
          rowx.high=+row.data.High;
          rowx.low=+row.data.Low;
          rowx.close=+row.data.Close;
          rowx.volume=+row.data.Volume;
          rowx.split="";
          rowx.dividend="";
          rowx.absoluteChange="";
          rowx.percentChange="";
      
          //console.log(rowx.date);
          //console.log("Row:", JSON.stringify(rowx));
          CHART_COTIS.push(rowx)
          //console.log("Row:", JSON.stringify(row.data));
          //console.log(parseData(parseDate))
        },
        error: function(err, file, inputElem, reason)
        {
          // executed if an error occurs while loading the file,
          // or if before callback aborted for some reason
          //console.log("XXXerror:"+JSON.stringify(err))
          //console.log("XXXreason:"+JSON.stringify(reason))
          //var ARRAY_COTIS="AAPL"
  
          let headers = new Headers(); 
          headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
          headers.set('Parameters',btoa(TICKER));
       
       
          fetch(glovar.URL_FETCH_COTIS_HISTORY,{ method:'GET', //mode: 'no-cors',
          headers: headers,
          })
         .then(res => res.json())
         .then(res => {
            //console.log("res:"+res)
           if(res=="auth/user-not-found" || res=="auth/wrong-password") {
             //try { setError("ERROR: "+i18n.t("email_try_again"));  } catch(Exx) {setError("ERROR: The email or password you entered is incorrect. Please try again.");   }
             return;
           }
       
           if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {
             //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
             return;
           }
       
           //console.log(JSON.stringify(res))
           
           const parseDate = timeParse("%Y-%m-%d");
           //var parseDate = timeParse("%Y-%m-%d %H:%M:%S");
  
           //rowx.date=parseDate(rowx.date);
  
           //console.log("antes bucle!")
           for(let j=0; j<res.length; j++) 
           {
            res[j].date=parseDate(res[j].date.split("T")[0]);
            //console.log(res[j].date)
           }
           //console.log("despues bucle!")
  
           
           setTodo(res)//.quoteResponse.result)
           /*if(MODE_USER_FECTH==0) {
             setMOREPOPULAR(res)
             try { glovar.MOREPOPULAR_COTIS=res;} catch(exxx) {}
           }
           else if(MODE_USER_FECTH==1) {
             setMYWATCHLIST(res)
             try { glovar.MYWATCHLIST_COTIS=res;} catch(exxx) {}
           }*/
         })
         .catch((e) => {
           //console.log(e);
          })
          .finally(() => {
           setIsFetching(false);
          });
  
  
  
        },
        complete: results => { 
  
          //console.log("ERROR:"+results.errors)
          setTodo(CHART_COTIS)
          setIsFetching(false);
          /*results.data.map((d) => {
            //console.log("d")
          });*/
          //console.log("All done!");
         
          //console.log(JSON.stringify(glovar.DATA_COTIS2))
        }
      });
      
        /*var results =Papa.parse(link, {
          download: true,
          complete: results => { 
            console.log(results.data)
            glovar.DATA_COTIS2=results.data;
            //return results;
          }
      })*/
      }
      catch(Exx) { /*console.log("ERROR CONEX:"+Exx)*/ 
    }
    
      

     });
    
    }


  },[]);
 
 //return { todoHistory, isFetchingHistory }
 return { todoHistory, todoData, isFetchingHistory, isFetchingData, todobestBTPROP  }
   

 //return { todoTopTraders }
 
 }