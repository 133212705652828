import React, { useState } from 'react';
import './CookieConsentPopup.css';
import glovar from './glovar';
import CookiePolicy from './CookiePolicy';
import SearchAssetFront from './SearchAssetFront';
import ShowAssetsSummary from './ShowAssetsSummary';
import ShowPopNewsletter from './ShowPopNewsletter';
import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';
import { Image } from "@chakra-ui/image";
import eventBus from "../eventBus";
import { SimpleGrid, GridItem, Text, Box, Stack, VStack, HStack,  Spacer, Flex } from "@chakra-ui/layout";
import i18n from "../../i18n"

import { getEmail } from './../../utils/Common';


import {
  
  Heading,
  Button,
  List,
  ListItem,
  ListIcon,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";


const plans = [
  {
    title: "Starter",
    price_m: "Free",
    price_y: "Free",
    features: [
      "Basic technical datasheets",
      "Limited execution backtesting tool",
      "Limited portfolio and favorites",
      "Basic stock market charting tool",
      "Limited Radar",
    ],
  },
  {
    title: "Pro1X PREMIUM",
    price_m: "$19",
    price_y: "$99",
    features: [
      "Full technical datasheets",
      "Find the BEST Strategy for TICKER",
      "Trend indicators (potential, target, etc...)",
      "Free of Ads.",
      "Full screen stock market charting tool",
      "Unlimited execution of backtesting on any searchable asset in Pro1X.",
      "Unlimited Opening/Closing of strategies on assets in your personal portfolio.",
      "Unlimited strategies on assets in your personal track record.",
      "Radar",
      "Full platform access, covering favorites, portfolio and asset datasheets/search tools.",
    ],
  },
];

function gotoPayment(billingOption) {

  //Premium mensual
  var pMode=(billingOption === "monthly" ? "M0" : "M1");

  // Crear un objeto con etiquetas y valores
const prepay_user = {
  mode_user:glovar.MODE_USER,
  mode_user_web:glovar.MODE_USER_WEB,
  user_username:glovar.USER_USERNAME,
  user_email:glovar.USER_EMAIL.replace(/^"(.*)"$/, '$1'),
  premiumMode: pMode,
};

  sessionStorage.setItem("prepay_user", JSON.stringify(prepay_user));
  //console.log("billingOption:"+billingOption)
  //console.log("guardo prepay_user:"+JSON.stringify(prepay_user));
  //console.log(JSON.stringify(getEmail("token123")));
  window.location.replace("/payment");
}

const  ShowLanding = ({ textType}) => {

  const [billingOption, setBillingOption] = useState("monthly");

   { // terms
      if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        return (
          <div>
      
      
      <HeaderMenu showSearch={false}/>
   
      <Box backgroundColor="#FFFFFF" /* minHeight="30em" */ minWidth="100%" pb={"0px"}>         
      <SimpleGrid   columns={14} gap={0} p={0} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={0} m={0} mt={0} paddingBottom={0}>
          <VStack width="100%" flexDirection="column" justifyContent="center" mt={2}>
       
          <Text textAlign="center" fontSize="26"  p={0} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("busca_tu_prox")}
           {1==2&&<Text textAlign="center" mt={0} fontSize="20" p={2} textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
                     {i18n.t("encuentra_los")}    
                     </Text>}
           </Text>

 <Box>
                
     
         </Box>

         <Image 
         style={{ marginTop:20, paddingBottom:0,  }} 
         src={`${process.env.PUBLIC_URL}/assets/pro1xpremium.png`} w={85} 
         //_hover={{ cursor: "pointer" }}
         // onClick= {() =>  {}} 
         />


         </VStack>
 
 
 
      
       
   </GridItem>


 

   <GridItem colSpan={14}  backgroundColor="#F8F8F8"  paddingBottom={150}>
            
  
        
             <Box py={10} px={5} maxW="100%" mx="auto">
             {1==2&&<Text textAlign="center" fontSize="30"  mb={5} textColor="black" fontFamily="Tahoma" fontWeight="semibold" >
        Plans & Pricing
      </Text>}
      {1==2&&<Text fontSize="lg" textAlign="center" mb={10}>
        Choose the plan that best fits your needs and get access to advanced tools and features to maximize your potential.
      </Text>}
      <SimpleGrid columns={1} marginLeft="0%" spacing={2} w="100%">
      {plans.slice(-1).map((plan, index) => (
        <Box
          key={index}
          borderWidth={plan.title === "Pro1X PREMIUM" ? "5px" : "3px"}
          borderColor={plan.title === "Pro1X PREMIUM" ? "black" : "gray.200"}
          borderRadius="lg"
          overflow="hidden"
          p={6}
          textAlign="left"
          boxShadow="lg"
          display="flex"
          flexDirection="column"
        >
          {/* Título del plan */}
          <Heading as="h3" size="lg" mb={4} textAlign="center">
            {plan.title}
          </Heading>

          {/* Contenedor del precio, botón y espacio reservado */}
          <Box
            mb={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="180px" // Altura fija para garantizar alineación entre los planes
          >
            {plan.title === "Pro1X PREMIUM" ? (
              <>
                <RadioGroup
                  onChange={setBillingOption}
                  value={billingOption}
                  mb={4}
                  textAlign="center"
                >
                  <Stack direction="row" justify="center">
                    <Radio value="monthly">Pay monthly</Radio>
                    <Radio value="yearly">
  <Text as="span" fontWeight="bold" >Pay yearly</Text>{" "}
  <Text as="span" color="black.500" fontWeight="bold">
    (Save 60% 💰)
  </Text>
</Radio>
                  </Stack>
                </RadioGroup>
                <Text fontSize="5xl" fontWeight="bold" textAlign="center" mb={4}>
                  {billingOption === "monthly" ? plan.price_m : plan.price_y}
                  <Text
                    as="span"
                    fontSize="md"
                    fontWeight="normal"
                    color="gray.500"
                  >
                    {billingOption === "monthly" ? "/MONTH" : "/YEAR"}
                  </Text>
                </Text>
                <Button
                  backgroundColor="black"
                  textColor="white"
                  size="lg"
                  w="full"
                  _hover={{ backgroundColor: "gray.500" }} // Cambia el color al pasar el cursor
                  onClick={() => {
                    //window.location.replace("/payment");
                    gotoPayment(billingOption)
                  }}
                >
                  Choose {plan.title}
                </Button>
              </>
            ) : (
              // Plan FREE
              <Text
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100px"
              >
                {plan.price_m}
              </Text>
            )}
          </Box>

          {/* Características del plan */}
          <VStack spacing={3} align="start">
            {plan.features.map((feature, i) => (
              <HStack key={i}>
                <Text as="span" fontWeight="bold"  >
                ✓
                </Text>
                <Text>{feature}</Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      ))}
    </SimpleGrid>
    </Box>
  
           
         
     </GridItem>


      </SimpleGrid>


    

    </Box> 

   <FooterMenu/>
      </div>
        );
     
      } 
      else {
        return (
          <div>
      
      
      <HeaderMenu showSearch={false}/>
   
      <Box backgroundColor="#FFFFFF" /* minHeight="30em" */ minWidth="100%" pb={"0px"}>         
      <SimpleGrid   columns={14} gap={0} p={0} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={0} m={0} mt={0} paddingBottom={0}>
          <VStack width="100%" flexDirection="column" justifyContent="center" mt={6}>
       
          <Text textAlign="center" fontSize="38"  p={0} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("busca_tu_prox")}
           <Text textAlign="center" mt={0} fontSize="20" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
                     {i18n.t("encuentra_los")}    
                     </Text>
           </Text>

 <Box>
                
     
         </Box>

         <Image 
         style={{ marginTop:20, paddingBottom:0,  }} 
         src={`${process.env.PUBLIC_URL}/assets/pro1xpremium.png`} w={120} 
         //_hover={{ cursor: "pointer" }}
         // onClick= {() =>  {}} 
         />


         </VStack>
 
 
 
      
       
   </GridItem>


 

   <GridItem colSpan={14}  backgroundColor="#F8F8F8"  paddingBottom={150}>
            
  
        
             <Box py={10} px={5} maxW="85%" mx="auto">
             {1==1&&<Text textAlign="center" fontSize="30"  mb={5} textColor="black" fontFamily="Tahoma" fontWeight="semibold" >
        Plans & Pricing
      </Text>}
      {1==2&&<Text fontSize="lg" textAlign="center" mb={10}>
        Choose the plan that best fits your needs and get access to advanced tools and features to maximize your potential.
      </Text>}
      <SimpleGrid columns={2} marginLeft="10%" spacing={10} w="80%">
      {plans.map((plan, index) => (
        <Box
          key={index}
          borderWidth={plan.title === "Pro1X PREMIUM" ? "5px" : "3px"}
          borderColor={plan.title === "Pro1X PREMIUM" ? "black" : "gray.200"}
          borderRadius="lg"
          overflow="hidden"
          p={6}
          textAlign="left"
          boxShadow="lg"
          display="flex"
          flexDirection="column"
        >
          {/* Título del plan */}
          <Heading as="h3" size="lg" mb={4} textAlign="center">
            {plan.title}
          </Heading>

          {/* Contenedor del precio, botón y espacio reservado */}
          <Box
            mb={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="180px" // Altura fija para garantizar alineación entre los planes
          >
            {plan.title === "Pro1X PREMIUM" ? (
              <>
                <RadioGroup
                  onChange={setBillingOption}
                  value={billingOption}
                  mb={4}
                  textAlign="center"
                >
                  <Stack direction="row" justify="center">
                    <Radio value="monthly">Pay monthly</Radio>
                    <Radio value="yearly">
  <Text as="span" fontWeight="bold" >Pay yearly</Text>{" "}
  <Text as="span" color="black.500" fontWeight="bold">
    (Save 60% 💰)
  </Text>
</Radio>
                  </Stack>
                </RadioGroup>
                <Text fontSize="5xl" fontWeight="bold" textAlign="center" mb={4}>
                  {billingOption === "monthly" ? plan.price_m : plan.price_y}
                  <Text
                    as="span"
                    fontSize="md"
                    fontWeight="normal"
                    color="gray.500"
                  >
                    {billingOption === "monthly" ? "/MONTH" : "/YEAR"}
                  </Text>
                </Text>
                <Button
                  backgroundColor="black"
                  textColor="white"
                  size="lg"
                  w="full"
                  _hover={{ backgroundColor: "gray.500" }} // Cambia el color al pasar el cursor
                  onClick={() => {
                    //window.location.replace("/payment");
                    gotoPayment(billingOption)
                  }}
                >
                  Choose {plan.title}
                </Button>
              </>
            ) : (
              // Plan FREE
              <Text
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100px"
              >
                {plan.price_m}
              </Text>
            )}
          </Box>

          {/* Características del plan */}
          <VStack spacing={3} align="start">
            {plan.features.map((feature, i) => (
              <HStack key={i}>
                <Text as="span" fontWeight="bold"  >
                ✓
                </Text>
                <Text>{feature}</Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      ))}
    </SimpleGrid>
    </Box>
  
           
         
     </GridItem>


      </SimpleGrid>


    

    </Box> 

   <FooterMenu/>
      </div>
        );
      }
      }

  }
  
  export default ShowLanding;