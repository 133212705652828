import {useState} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
//import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
//import {auth} from './firebase'
import {useNavigate} from 'react-router-dom'
//import {useAuthValue} from './AuthContext'

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text} from "@chakra-ui/layout";
//import { Stack } from "@chakra-ui/layout";
//import { Grid } from "@chakra-ui/layout";
//import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { FiPlus } from "react-icons/fi";
//import { HStack } from "@chakra-ui/layout";
//import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";

//import glovar from './components/glovar';
import glovar from './components/content/glovar';
import HeaderMenu from './components/content/HeaderMenu';

//import { Spacer } from "@chakra-ui/layout";
//import { Flex } from "@chakra-ui/layout";
import eventBus from "./components/eventBus";

import "./App.css";
import { getToken, removeUserSession, setUserSession, setUserSessionStocks, 
         getPortfolio,setModeUser, setCurrent_porfolio_ED, getporfolio_ED } from './utils/Common';
//import { getFirestore, collection, getDocs,getDoc,doc } from 'firebase/firestore';
//import {db} from './firebase'
import i18n from './i18n'

//console.log("HE ENTRADO EN LOGIN!!!!")
  
async function getFBDocs(docUser) {

  glovar.CURRENT_USER=null;
 
  try { glovar.APP_STATE_STATE=docUser.appState; } catch(Exxx) { glovar.APP_STATE_STATE="none"; }
  try 
  { 
   if(glovar.APP_STATE_STATE==glovar.APP_STATE_ACTIVE) {  glovar.APP_STATE_RUNNING=true; }
  }
  catch(Exxxs) {}  

  
  {
    //console.log('#CARTERA PREEEEEEEEEEMIUM!!!!!!!!!!');
    //console.log(docUser);
    //console.log(docUser.id, " => ", docUser.data());
  
    var SHOW_CARTERA=0;
   
    const lang =glovar.APP_LANG;
    var NEW_PRIORITY=-1;
    SHOW_CARTERA=1;
  
    //console.log('(docUser.get(lang):'+docUser.get('lang'));
  
      
  
    //if(SHOW_CARTERA==1 || docUser.get('priority')<1000)
    {
  
     var cartera = {
      rentab: 0,
      name: 'GLOBAL STOCKS',
      idCartera: 'ID_GLOBAL',
      descript: 'Global stock portfolio description',
      risk_level: 3,
      max_level: 5,
      countriesID: ' ',
      activos: '1,2,3',
      op_cerradas: 26,
      rentmedia_op: 13.27,
      sentido: 1,
      temporal: 1,
      historial:'',
      cartera_text:'',
      cartera_ed:[],
      cartera:[],
      favoritos:[], 
      capital:0,
      invertido:0,
      moneda:' ',
      profit:0,
      priority:0,
      rentab_acum:0,
      type_cartera:2, //por defecto solo la pueden ver los premiums!
      op_win: 8,
      followers: 333,
      cap_inicial:1000000,
      ranking:1,
      lastDataUpdateCartera_ed:0,
      lastDataUpdateFavoritos:0,
      lastDataUpdateHistorial:0,
      lastDataUpdatePortfolio: 0
    };
  
    try {
      cartera.moneda=docUser.moneda;
      cartera.currency=docUser.currency;
    }
    catch(Exx) {}

    try { cartera.lastDataUpdateCartera_ed=docUser.lastDataUpdateCartera_ed; } catch(Exxx) {}
    try { cartera.lastDataUpdateFavoritos=docUser.lastDataUpdateFavoritos; } catch(Exxx) {}
    try { cartera.lastDataUpdateHistorial=docUser.lastDataUpdateHistorial; } catch(Exxx) {}
    try { cartera.lastDataUpdatePortfolio=docUser.lastDataUpdatePortfolio;  } catch(Exxx) {}

  
    //console.log("cartera.lastDataUpdateCartera_ed:"+cartera.lastDataUpdateCartera_ed)
    //console.log("cartera.lastDataUpdateFavoritos:"+cartera.lastDataUpdateFavoritos)
    //console.log("cartera.lastDataUpdateHistorial:"+cartera.lastDataUpdateHistorial)
    //console.log("cartera.lastDataUpdatePortfolio:"+cartera.lastDataUpdatePortfolio)
   
    if(NEW_PRIORITY>=0)
    {
     cartera.priority=NEW_PRIORITY;
    }
    else {
     try { cartera.priority=docUser.get('priority'); } catch(Exxx) {}
    }
   
  
  
    try { cartera.rentab=docUser.rentab; } catch(Exxx) {}
  
  
    try {
     if (glovar.APP_FORCE_LANG=='ES') {  cartera.name=docUser.name; }
     else { cartera.name=docUser.name_en; }
    }
    catch(car1) { try { cartera.name=docUser.name; } catch(Exxx) {}}
    //cartera.name=docUser.name');
  
   
    try { cartera.idCartera=docUser.idCartera; } catch(Exxx) {}
  
    try {
    if (glovar.APP_FORCE_LANG=='ES') {  cartera.descript=docUser.descript; }
    else { cartera.descript=docUser.descript_en; }
   }
   catch(car1) { try { cartera.descript=docUser.descript; } catch(Exxx) {} }
  
  
   /*
   try {
     if (glovar.APP_FORCE_LANG=='ES') {  cartera.descript_full=docUser.descript_full'); }
     else { cartera.descript_full=docUser.descript_full_en'); }
    }
    catch(car1) {cartera.descript_full=docUser.descript_full');}
    */
  
    //cartera.descript=docUser.descript');
  
    try { cartera.cartera_ed=docUser.cartera_ed; } catch(Exxx) {}

    try {
      if(docUser.cartera_ed.length<3)
      {
        var newCarteraED = {

          rentab:docUser.rentab,
          max_level:docUser.max_level,
          garantia:docUser.garantia,
          countriesID:docUser.countriesID,
          cartera_text:docUser.cartera_text,
          temporal:docUser.temporal,
          descript:docUser.descript,
          rentmedia_op:docUser.rentmedia_op,
          profit_acum:docUser.profit_acum,
          invertido:docUser.invertido,
          activos:docUser.activos,
          lastDataUpdatePortfolio:docUser.lastDataUpdatePortfolio,
          rentab_acum:docUser.rentab_acum,
          currency:docUser.currency,
          moneda:docUser.moneda,
          capital:docUser.capital,
          risk_level:docUser.risk_level,
          currentmoney:docUser.currentmoney,
          op_cerradas:docUser.op_cerradas,
          profit:docUser.profit,
          idCartera:docUser.idCartera,
          historial:docUser.historial,
          capitalUsed:docUser.capitalUsed,
          sentido:docUser.sentido,
          lastDataUpdateHistorial:docUser.lastDataUpdateHistorial,
          lastDataUpdateFavoritos:docUser.lastDataUpdateFavoritos,
          name:docUser.name,
          lastDataUpdateCartera_ed:docUser.lastDataUpdateCartera_ed,
          cartera:docUser.cartera
        
         }

         //console.log("añadimos cartera ED!!!")
         cartera.cartera_ed=newCarteraED;
        
      }
    }
    catch(exx) {}


    try {

      cartera.favoritos=docUser.favoritos;
      
    cartera.risk_level=docUser.risk_level;
    cartera.max_level=docUser.max_level;
    cartera.countriesID=docUser.countriesID;
  
    cartera.activos=docUser.activos;
    cartera.op_cerradas=docUser.op_cerradas;
    cartera.rentmedia_op=docUser.rentmedia_op;
    cartera.sentido=docUser.sentido;
    cartera.temporal=docUser.temporal;
    cartera.historial=docUser.historial;
    cartera.cartera_text=docUser.cartera;
  
    cartera.cartera=docUser.cartera;

    cartera.capital=docUser.capital;
    cartera.invertido=docUser.invertido;
    cartera.moneda=docUser.moneda;
    cartera.profit=docUser.profit;
    cartera.rentab_acum=docUser.rentab_acum;
  } catch(Exxx) {}

    try { cartera.type_cartera=docUser.type_cartera_android; if(cartera.type_cartera==null) { cartera.type_cartera=docUser.type_cartera; } }
    catch (exxxx) { try { cartera.type_cartera=docUser.type_cartera; } catch(Exxx) {} }
  
    //User banneado de carteras FREE con ads
    if(glovar.USER_BANNED_TYPE_CARTERA==1)
    {
     if(cartera.type_cartera>2) { cartera.type_cartera='2'; }
    }
   try {
    cartera.acciones=docUser.acciones;
    cartera.coti=docUser.coti;
  
    cartera.op_win=docUser.op_win;
    cartera.followers=docUser.followers;
    cartera.ranking=docUser.ranking;
    cartera.id=docUser.id;
  } catch(Exxx) {}
    //Falta comprobarlo con la BD
    cartera.subscribed=false;
  
    
  
    try 
    {
     if(glovar.listTopTradersSUBS!=null && glovar.listTopTradersSUBS.length>0)
     {
      for(let z=0; z<glovar.listTopTradersSUBS.length; z++)
      {
       try { if(glovar.listTopTradersSUBS[z].id==cartera.id) { 
         cartera.subscribed=true;
         //console.log('TROBAT SUBSCRIBED!!!')
         break } } catch(Exxxx) {}
      }
     }
    }
    catch(Exxxxa) {}
    //console.log('cartera.type_cartera:'+cartera.type_cartera);
  
    //const ID_CARTERA=docUser.idCartera');
  
  
    //glovar.TOPTRADERS_PRO1.push(cartera);
    glovar.CURRENT_USER=cartera;

    //console.log("glovar.CURRENT_USER:"+JSON.stringify(glovar.CURRENT_USER));

    glovar.current_porfolio_ED=cartera.cartera_ed;

    try { glovar.current_porfolio_ED.lastDataUpdateCartera_ed=cartera.lastDataUpdateCartera_ed; } catch(Exxx) {}
    try { glovar.current_porfolio_ED.lastDataUpdateFavoritos=cartera.lastDataUpdateFavoritos; } catch(Exxx) {}
    try { glovar.current_porfolio_ED.lastDataUpdateHistorial=cartera.lastDataUpdateHistorial; } catch(Exxx) {}
    try { glovar.current_porfolio_ED.lastDataUpdatePortfolio=cartera.lastDataUpdatePortfolio;  } catch(Exxx) {}
    
    
    try { glovar.current_porfolio_ED.moneda=cartera.moneda; } catch(Exx) {}
    try { glovar.current_porfolio_ED.currency=cartera.currency; } catch(Exx) {}

    setCurrent_porfolio_ED(glovar.current_porfolio_ED);

    //console.log("LOGIN current_porfolio_ED:"+JSON.stringify(glovar.current_porfolio_ED))

    //console.log("vencimiento web:"+docUser.data().vencimiento_web)
    //console.log('#countriesID:'+docUser.get('countriesID'));
    //console.log('#014"op_cerradas:'+docUser.data().get('op_cerradas'));
    //console.log('#### 0 TOP TRADERS!!!!!!!!:'+cartera.idCartera+' '+cartera.subscribed);
    
    //nom=glovar.TOPTRADERS_PRO1[0].idCartera//cartera.idCartera;
    //this.setState({});

    try 
       {   
         glovar.MODE_USER_WEB=parseInt(docUser.mode_user_web);

         if(glovar.MODE_USER_WEB==2) {
          glovar.MODE_USER=2;
         }

        
         try { if(!glovar.MODE_USER_WEB) { glovar.MODE_USER_WEB=0} } catch(exxx) {}

         //try 
         //{ 
         // glovar.MODE_USER_RADAR_WEB=parseInt(doc.get('mode_user_radar_web'));
         // try { if(!glovar.MODE_USER_RADAR_WEB) { glovar.MODE_USER_RADAR_WEB=0} } catch(exxx) {}
         //}
         //catch(Exxx) {}

        if(glovar.MODE_USER_WEB==2) 
        {
        //console.log('##############################') 
        //console.log('------------------------------');
        //console.log('SUSCRIPCIÓ ACTIVA')
        //console.log('MODE_USER_WEB2')
        //console.log('##############################') 
        const timeweb=parseInt(docUser.vencimiento_web)
        var d=new Date();
        const currentTime = (d.getTime()/1000); //vencimiento en segundos, no en milisegundos (000)
        const milisPREMIUM=timeweb-currentTime;
        //console.log('#VENCIMIENTO WEB:'+timeweb)
        //console.log('#current TIMEAPP:'+currentTime)
        //console.log('#MILIS para VENCIMIENTO PREMIUM:'+milisPREMIUM);
        if(milisPREMIUM>0)
        { 
          glovar.MODE_USER=2;
          setModeUser(glovar.MODE_USER) 
        }

       }
      }
      catch(Exception1) { /*console.log('PETADA EXCEPTION1:'+Exception1);*/  }
   
    
   }
   }//);

   
   var ARRAY_COTIS="";
   glovar.CURRENT_FAVORITOS=null;
   glovar.CURRENT_FAVORITOS = [];

   glovar.CURRENT_PORTFOLIO=null;
   glovar.CURRENT_PORTFOLIO = [];

   glovar.CURRENT_HISTORIAL=null;
   glovar.CURRENT_HISTORIAL = [];

   let j=0;
   //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
   {
    //if(glovar.TOPTRADERS_PRO1[j].idCartera==glovar.CURRENT_TRADER)
    try 
    {
      //console.log("SE CUMPLE LA CONDICION!!!")
      //glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial.split('},');
      glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial;
    }
    catch(Exxx) {}
   }

   //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
   {
    

    //console.log("Hola 0000")
    glovar.CURRENT_FAVORITOS=glovar.CURRENT_USER.favoritos;
    //console.log("Hola 0001:"+glovar.CURRENT_USER.favoritos)
      

   }

   try 
   {
     
     glovar.CURRENT_PORTFOLIO=glovar.CURRENT_USER.cartera;
     
   
   }
   catch(Exxx) {}




  


  
  //this.setState({});
    //console.log(data); // JSON data parsed by `data.json()` call
 


   
   glovar.FIREBASE_LOADED=1;


   if(1==1) {
   try 
        {
         var headers = new Headers(); 

         //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
         //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)

         headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
         headers.set('Content-Type', 'application/json');
     
         //glovar.CURRENT_USER=cartera;
         //glovar.current_porfolio_ED=cartera.cartera_ed;
                      
          //console.log(JSON.stringify(headers))

          var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
          carteraPARSED = carteraPARSED.replace('[', '');
          carteraPARSED = carteraPARSED.replace(']', '');

          var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
          historialPARSED = historialPARSED.replace('[', '');
          historialPARSED = historialPARSED.replace(']', '');

          var favoritosPARSED=JSON.stringify(glovar.CURRENT_FAVORITOS);
          favoritosPARSED = favoritosPARSED.replace('[', '');
          favoritosPARSED = favoritosPARSED.replace(']', '');

         const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
               //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
               //method: "POST",
               method: "PUT",  
               headers: headers,
              
               body: 
               JSON.stringify({ 
                rentab: glovar.current_porfolio_ED.rentab,
                name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                risk_level: glovar.current_porfolio_ED.risk_level,
                max_level: glovar.current_porfolio_ED.max_level,
                countriesID:glovar.current_porfolio_ED.countriesID,
                activos: glovar.current_porfolio_ED.activos,
                op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                sentido: glovar.current_porfolio_ED.sentido,
                temporal: glovar.current_porfolio_ED.temporal,
                historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                favoritos: favoritosPARSED,
                //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                capital:glovar.current_porfolio_ED.capital,
                invertido:glovar.current_porfolio_ED.invertido,
                moneda:glovar.current_porfolio_ED.moneda,
                profit:glovar.current_porfolio_ED.profit,
                currency:glovar.current_porfolio_ED.currency,
                priority:glovar.current_porfolio_ED.priority,
                rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
  
           //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
           //descript: "que pasa nen me voy de subidon!"
              })
         }) 

         //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
        }
        catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
      }

  // this.setState({});
   
   //if(MUST_REFRESH) { MUST_REFRESH=false;  window.location.reload(); }
   //
  }

  function Login(){

    //console.log("Entro en Login!!!!")

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('') 
    const [error, setError] = useState('')
    //const {setTimeActive} = useAuthValue()
    const navigate = useNavigate()
  
   // console.log("Entro en Login!!!!")
    
    const login = e => {
      e.preventDefault()
  
      
   //console.log("email:"+email)
   //console.log("password:"+password)
  
   //let username1 = glovar.USER_API//'admin';
   //let password1 = glovar.PASS_API//'password';
   let headers = new Headers(); 
   headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
   headers.set('Parameters', 'Basic ' + btoa(email + ":" + password));


   //fetch('http://localhost:3010/api/loginuser?email='+email+'&password='+password,{ method:'GET', //mode: 'no-cors',
   

   //console.log("glovar.URL_BACKEND_PROD:"+glovar.URL_BACKEND_PROD)
   //console.log("process.env.URL_BACKEND_PROD:"+process.env.URL_BACKEND_PROD)
   //console.log("process.env.USER_API:"+JSON.stringify(process.env.REACT_APP_USER_API))
   //console.log("process.env:"+JSON.stringify(process.env))
   fetch(glovar.URL_BACKEND_PROD+'/api/loginuser',{ method:'GET', //mode: 'no-cors',
   headers: headers,
   })
   //fetch('http://localhost:3010/api/loginuser?email='+email+'&password='+password)
  .then(res => res.json())
  .then(res => {
    //glovar.TOPTRADERSLIST=res;
    //console.log(res)

    if(res=="auth/user-not-found" || res=="auth/wrong-password") {
      try { setError("ERROR: "+i18n.t("email_try_again"));  }
      catch(Exx) {setError("ERROR: The email or password you entered is incorrect. Please try again.");   }
      return;
    }

    if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {
      setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
      return;
    }
    

    /*for(let ii=0; ii<res.length; ii++)
    {
     console.log("TOPTRADER: "+res[ii].name) 
    }*/
  //});
  
  var userFire = res.userfire;
      //signInWithEmailAndPassword(auth, email, password)
      //auth().signInWithEmailAndPassword(email, password)
      //.then(() => 
     // {
        /*if(!auth.currentUser.emailVerified) 
        {
  
          sendEmailVerification(auth.currentUser)
          .then(() => {
            //setTimeActive(true)
            navigate('/verify-email')
          })
        .catch(err => alert(err.message))
      }
      else*/
      {
        glovar.USER_LOADED=true;
        glovar.MODE_USER_WEB=0;
        var nameFire, emailFire, photoUrlFire, uidFire, emailVerifiedFire;
  
        
        if (userFire != null) 
        {
          //console.log("userFire:"+JSON.stringify(userFire));

          var nameREAL=''
          var nameRAW=userFire.displayName;
          //var nameRAW='02_name69_69';//userFire.displayName;
          //console.log("nameRAW:"+nameRAW);
          var i = nameRAW.indexOf('_');
          var MODE_USER_STRING = nameRAW.slice(0, i).trim();
          nameREAL = nameRAW.slice(i + 1, nameRAW.length).trim();
  
        
         nameFire = nameREAL;//userFire.displayName;
         emailFire = userFire.email;
         //photoUrlFire = userFire.photoURL;
         //emailVerifiedFire = userFire.emailVerified;
         //uidFire = userFire.uid;  // The user's ID, unique to the Firebase project. Do NOT use this value to authenticate with your backend server, if you have one. Use User.getToken() instead.
  
         //console.log('69nameFire:',nameFire);
         //console.log('emailFire:',emailFire);
          //Actualitzem variables GLOBALS 
          glovar.USER_USERNAME=nameFire;
          glovar.USER_EMAIL=emailFire;
        
          //Por defecto a la espera de leer en BD
          glovar.MODE_USER=1; //USER REGISTRADO PERO NO HA PAGADO  
  
          if(MODE_USER_STRING=='02')
          {
            glovar.MODE_USER=2; 
          }
          glovar.USER_CHECKSUBS=false;//checked_subs;
  
          
          
          
          try {
            setUserSession("token123", glovar.USER_USERNAME, glovar.USER_EMAIL,glovar.MODE_USER);
          }
          catch(Exx) {}
          //this.setState({});
      
          getFBDocs(res.docUser).then(() => 
          {
           try {
               //console.log("1CURRENT_PORTFOLIO:"+JSON.stringify(glovar.CURRENT_PORTFOLIO));
              setUserSessionStocks("token123", glovar.CURRENT_FAVORITOS, glovar.CURRENT_HISTORIAL,glovar.CURRENT_PORTFOLIO);            
            }
            catch(Exx) {}

            //Ho torno a salvar pq en getFBDocs hem actualitzat el mode_user (si aquest és PREMIUM)
            if(glovar.MODE_USER==2) {
             try {
              setUserSession("token123", glovar.USER_USERNAME, glovar.USER_EMAIL,glovar.MODE_USER);
              console.log("ESE PREMIUM!!!!!!!!!!!!!!")
             }
             catch(Exx) {}
            }

            eventBus.dispatch("refreshDB", { message: "" });
            //navigate('/')
            //console.log("LOGIN glovar.current_porfolio_ED.invertido:"+glovar.current_porfolio_ED.invertido);
            window.location.replace('/home')
            //window.replace('/home')
           
          })
          .catch((error) => {
            console.error(error);
            eventBus.dispatch("refreshDB", { message: "" });
            //navigate('/')
            window.location.replace('/home')
          });

          try {
           eventBus.dispatch("loginUser", { data:  glovar.USER_USERNAME });
          }
          catch(Exx) {}

        
      }
  
      
   
        //glovar.USER_USERNAME=
  
        /*
        try {
          setUserSession("token123", glovar.CURRENT_TRADER);
        }
        catch(Exx) {}
        //this.setState({});
    
          navigate('/')
          */
      }
      })
      //.catch(err => alert(err.message))
      //.catch(err => setError(err.message))
      .catch((err) => {  
        //console.log(JSON.stringify(err)); 
        //{"code":"auth/user-not-found","customData":{},"name":"FirebaseError"}
  
        try {
          if(err.code=="auth/user-not-found") {
            setError("ERROR: The email or password you entered is incorrect. Please try again.");  //Please try again or contact support if you are unable to access your account");
          }
          else {
            setError("Error")
          }
        }
        catch(Excp) {setError("Error");}//+Excp)}
        //setError(err.message)
      }
      
     )
    }

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
    //if(1==1)
     if(glovar.MOBILE_LOGIN_ALLOWED!=1)
     {
        //console.log("HOLA SOY OLOGIIIIIIIIIIIIIIIIIN!")
        return (
          <Box onClick= {() => window.location.replace('/home') }>
           
           <Box p={10} pb={0}>
           <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall.png`} h="100px" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          //try { sendEventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/home') 
        }}
       /> 
       </Box>
         
         
          <div className="formCenterCreateAccount" background={"yellow"}>
          <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"3pc" }}>{i18n.t("login")}</Text>
         
          <Text  fontSize="1.1em" marginBottom={10} marginRight={3} fontWeight="normal" textColor="gray.800">
          The <b>mobile version</b> of the website has temporarily <b>limited functionality</b> and does not allow login access.</Text>
                <Text  fontSize="1.1em" marginBottom={10}  marginRight={3}  textColor="gray.800">
                To <b>access all the options and tools</b> offered by Pro1X.com, you need to <b>log in from the Desktop version</b>.</Text>

              {1==2&&<Text  fontSize="1.1em" marginBottom={10} marginRight={3} fontWeight="normal" textColor="gray.800">
                La web <b>versión mobile</b> tiene temporalmente <b>limitadas algunas funcionalidades</b> y no permite inicio de sesión.</Text>}
                {1==2&&<Text  fontSize="1.1em" marginBottom={10}  marginRight={3}  textColor="gray.800">
                Para <b>acceder a todas las opciones y herramientas</b> que ofrece Pro1X.com es necesario <b>iniciar sesión desde su versión Desktop (ordenador).</b> </Text>}
                
                {1==2&&<Text fontSize="1.1em" fontWeight="bold" marginTop="5pc" marginBottom="1pc" marginLeft="3.5pc" textColor="gray.800">
                www.Pro1X.com</Text>}
            
          </div>
        
          
          </Box>
        );
     } else {
      return (

        <Box>
      <HeaderMenu showSearch={false}/>
        <div className="mobformCenterTop" background={"yellow"}>
        {1==2&&<Box   _hover={{ cursor: "pointer" }}>
          <div onClick= {() => window.location.replace('/home') }>
          <Text style={{fontSize:"1em", fontWeight:"semibold", textDecoration:"underline", marginBottom:"2pc" }}>{i18n.t("acceder_user_anom")}</Text>
          </div>
          </Box>}
        <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"3pc" }}>{i18n.t("ini_ses_tucuenta")}</Text>
        {error && <div className='auth__error'>{error}</div>}
          <form className="formFields" onSubmit={login}>
            <div className="mobformField">
              <label className="formFieldLabel" htmlFor="email">
                E-Mail
              </label>
              <input
                type="email"
                id="email"
                className="formFieldInput"
                placeholder={i18n.t("intro_email")}
                name="email"
                value={email}
                required
                onChange={e => setEmail(e.target.value)}
                //value={this.state.email}
                //onChange={this.handleChange}
    
    
              />
            </div>
    
            <div className="mobformField">
              <div className="div_hor_left">
              <label className="formFieldLabel" htmlFor="password">
                Password
              </label>
              </div>
              <div className="div_hor_right">
              <Text
          fontWeight="semibold"
          fontSize={13}
        >
              <Link to='/forgotPassword'>{i18n.t("has_forgot_pass")}</Link>
              </Text>
              </div>
              <input
                type="password"
                id="password"
                className="formFieldInput"
                placeholder={i18n.t("intro_pass")}
                name="password"
                value={password}
                required
                onChange={e => setPassword(e.target.value)}
              />
            </div>
    
            <div className="mobformField">
              <button className="formFieldButton">{i18n.t("mini_ses")}</button>{" "}
              
            </div>
            
            <SimpleGrid columns={2} gap={0} width="90%" marginTop="-4%" marginLeft="0%" alignContent="center" textAlign="center">
        <GridItem colSpan={1}>
            <Text
          fontWeight="300"
          size="14"
          
          textColor="gray"
        >
         {i18n.t("nuevo_platform")}
        </Text>
        </GridItem>
        <GridItem colSpan={1}>
            
        <Text
          fontWeight="bold"
          size="14"
          //textDecorationLine={"underline"}
          textColor="blackAlpha.800"
          _hover={{ cursor: "pointer" }}
        >
          {glovar.REGISTER_ONLINE==true&&<Link to='/register'>{i18n.t("create_account")}</Link>}
          {glovar.REGISTER_ONLINE==false&&<Link to='/registerApp'>{i18n.t("create_account")}</Link>}
  
         
  
        </Text>
        
        </GridItem>
        </SimpleGrid>
           
          </form>
        </div>
        
        
        </Box>
      );
     }
    } 
    else {
    return (
      <Box>
        <SimpleGrid columns={6} gap={3} >
        <GridItem colSpan={4} background={"#000000"/*"#F8F8F8"*/} height="100vh">
        <Box   _hover={{ cursor: "pointer" }}>
        <div onClick= {() => window.location.replace('/home') }>
        <img className="verticalhorizontal" src='assets/logopro1xsmall1.png' w={60}  />
        </div>
        </Box>
        
      </GridItem>
      <GridItem colSpan={2}  height="100vh" >
      <div className="formCenterTop" background={"yellow"}>
      <Box   _hover={{ cursor: "pointer" }}>
        <div onClick= {() => window.location.replace('/home') }>
        <Text style={{fontSize:"1em", fontWeight:"semibold", textDecoration:"underline", marginBottom:"5pc" }}>{i18n.t("acceder_user_anom")}</Text>
        </div>
        </Box>
      <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"3pc" }}>{i18n.t("ini_ses_tucuenta")}</Text>
      {error && <div className='auth__error'>{error}</div>}
        <form className="formFields" onSubmit={login}>
          <div className="formField">
            <label className="formFieldLabel" htmlFor="email">
              E-Mail
            </label>
            <input
              type="email"
              id="email"
              className="formFieldInput"
              placeholder={i18n.t("intro_email")}
              name="email"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
              //value={this.state.email}
              //onChange={this.handleChange}
  
  
            />
          </div>
  
          <div className="formField">
            <div className="div_hor_left">
            <label className="formFieldLabel" htmlFor="password">
              Password
            </label>
            </div>
            <div className="div_hor_right">
            <Text
        fontWeight="semibold"
        fontSize={13}
      >
            <Link to='/forgotPassword'>{i18n.t("has_forgot_pass")}</Link>
            </Text>
            </div>
            <input
              type="password"
              id="password"
              className="formFieldInput"
              placeholder={i18n.t("intro_pass")}
              name="password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          </div>
  
          <div className="formField">
            <button className="formFieldButton">{i18n.t("mini_ses")}</button>{" "}
            
          </div>
          
          <SimpleGrid columns={2} gap={0} width="65%" marginTop="-4%" marginLeft="0%" alignContent="center" textAlign="center">
      <GridItem colSpan={1}>
          <Text
        fontWeight="300"
        size="14"
        
        textColor="gray"
      >
       {i18n.t("nuevo_platform")}
      </Text>
      </GridItem>
      <GridItem colSpan={1}>
          
      <Text
        fontWeight="bold"
        size="14"
        //textDecorationLine={"underline"}
        textColor="blackAlpha.800"
        _hover={{ cursor: "pointer" }}
      >
        {glovar.REGISTER_ONLINE==true&&<Link to='/register'>{i18n.t("create_account")}</Link>}
        {glovar.REGISTER_ONLINE==false&&<Link to='/registerApp'>{i18n.t("create_account")}</Link>}

       

      </Text>
      
      </GridItem>
      </SimpleGrid>
         
        </form>
      </div>
      </GridItem>
      </SimpleGrid>
      </Box>
    );
  }
  }



export default Login