import { Text, Spacer, Heading, Flex, Box,Stack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import React, { useState , useEffect} from "react";
import eventBus from "../eventBus";
import glovar from './glovar';

import i18n from "../../i18n"


var TOP_TRADER;
// Funciones para manejar eventos
function showElementTicker(element) {
  eventBus.dispatch("showElementTicker", { el: element });
}


// Obtiene el valor actual de CURRENT_HISTORIAL
const getCurrentHistorial = () => glovar.CURRENT_HISTORIAL;


// Componente Wallet
const Wallet = React.memo(({
  element,
  own_user,
  pos,
  title,
  acciones,
  sentido,
  salida,
  entrada,
  benef,
  rent,
  color1,
  color2,
  fent,
  fsal,
  colortext,
  colorback_tit,
  mobile,
  isOptionsVisible,
  toggleOptions
}) => (
  <Box mr={2} mt={0} mb={5} color={colortext} _hover={{ cursor: "pointer" }}
  onClick={() => 
     { 
      if(own_user == 1) { toggleOptions(pos); } 
      else {
        //Ir a la ficha del valor
        //TOP_TRADER.symbol
        //showElementTicker(element);
        //console.log("TOP_TRADER:"+JSON.stringify(element))
        window.location.replace('/symbol/'+element.symbol)
      }
     }}>
    <Flex alignItems="center" mb={3}>
      {/* Información principal */}
      {!mobile && (
        <Box ml={0} w={"11pc"}>
          <Heading fontSize="md" w={"13pc"}>{title}</Heading>
          <Text fontSize="13" fontWeight="thin">
            {fent}  |  {fsal}
          </Text>
        </Box>
      )}
      {mobile && (
        <Box ml={0} w={"7.5pc"}>
          <Heading fontSize="sm" w={"7.5pc"}>{title}</Heading>
          <Text fontSize="11" fontWeight="thin">
            {fent}  |  {fsal}
          </Text>
          <Box
            borderRadius="lg"
            fontSize="12"
            fontWeight="semibold"
            textAlign="center"
            mr={4}
            mt={1}
            w={"4pc"}
            p={1}
            bg={colorback_tit}
            textColor="#FFFFFF"
          >
            {sentido == 1 ? "+" + acciones : "-" + acciones}
          </Box>
        </Box>
      )}
      {!mobile && (
        <Box
          borderRadius="lg"
          fontSize="12"
          fontWeight="semibold"
          textAlign="center"
          mr={4}
          w={"4pc"}
          p={1}
          bg={colorback_tit}
          textColor="#FFFFFF"
        >
          {sentido == 1 ? "+" + acciones : "-" + acciones}
        </Box>
      )}
      <Spacer />
      {!mobile && (
        <Box
          borderRadius="lg"
          fontSize="14"
          fontWeight="semibold"
          textAlign="center"
          w={"10.5pc"}
          p={1}
          ml={0}
          bg={color1}
          textColor={color2}
        >
          {benef}     {rent}
        </Box>
      )}
      {mobile && (
        <Box
          borderRadius="lg"
          fontSize="14"
          fontWeight="semibold"
          textAlign="center"
          w={"9pc"}
          p={1}
          ml={0}
          bg={color1}
          textColor={color2}
        >
          {benef}     {rent}
        </Box>
      )}
      <Spacer />
      {!mobile && (
        <Box textAlign="right" mr={0} w={"4pc"}>
          <Text fontSize="14" fontWeight="semibold">
            {entrada}
          </Text>
        </Box>
      )}
      <Spacer />
      {!mobile && (
        <Box textAlign="right" ml={2} w={"4pc"}>
          <Text fontSize="14" fontWeight="semibold">
            {salida}
          </Text>
        </Box>
      )}
      {mobile && (
        <Box textAlign="right" ml={2} w={"4pc"}>
          <Text fontSize="14" fontWeight="semibold">
            {entrada}
          </Text>
          <Text fontSize="14" fontWeight="semibold">
            {salida}
          </Text>
        </Box>
      )}
    </Flex>

    {/* Opciones adicionales */}
    {isOptionsVisible && (
      <Flex alignItems="center">
        {!glovar.APP_STATE_RUNNING && (
          <Box
            _hover={{ cursor: "pointer" }}
            p={1.5}
            m={1}
            marginTop={2}
            ml={0}
            borderRadius="md"
            bg="gray"
            textColor="white"
            onClick={(e) => {
              e.stopPropagation(); // Evitar que el clic cierre la opción
              eventBus.dispatch("showDeletePosTrackRecord", { pos: (pos - 1) });
            }}
          >
            <Image src={`${process.env.PUBLIC_URL}/assets/ic_delete_forever_white_24dp.png`} w={6}></Image>
          </Box>
        )}

      {!glovar.APP_STATE_RUNNING && (
          <Box _hover={{ cursor: "pointer" }} p={1.5} m={1}  marginTop={2} ml={2} borderRadius="md" bg="gray" textColor="white" 
           onClick={() => window.location.replace('/symbol/'+element.symbol) }>
          <Image src={`${process.env.PUBLIC_URL}/assets/ic_chart_areaspline_white_24dp.png`} w={6}></Image>
          </Box>)}


        {glovar.APP_STATE_RUNNING && (
          <Box
            _hover={{ cursor: "pointer" }}
            p={1.5}
            m={1}
            marginTop={2}
            ml={0}
            borderRadius="md"
            bg="#DDDDDD"
            textColor="white"
          >
            <Image src={`${process.env.PUBLIC_URL}/assets/ic_delete_forever_white_24dp.png`} w={6}></Image>
          </Box>
        )}
        {glovar.APP_STATE_RUNNING && (
          <Text
            style={{
              fontSize: "0.9em",
              fontWeight: "normal",
              marginLeft: "1pc",
              textAlign: "center",
              alignContent: "center"
            }}
          >
            INFO: Para poder eliminar posición en tu track record es necesario que salgas de la APP.
          </Text>
        )}
      </Flex>
    )}
  </Box>
), areEqual);

// Función de comparación personalizada para evitar renderizados innecesarios
function areEqual(prevProps, nextProps) {

  return false;
  
  return (
    prevProps.title === nextProps.title &&
    prevProps.acciones === nextProps.acciones &&
    prevProps.benef === nextProps.benef &&
    prevProps.rent === nextProps.rent &&
    prevProps.sentido === nextProps.sentido &&
    prevProps.entrada === nextProps.entrada &&
    prevProps.salida === nextProps.salida &&
    prevProps.mobile === nextProps.mobile &&
    prevProps.isOptionsVisible === nextProps.isOptionsVisible
  );
}

// Componente Item
function Item(props) {
  var REVISEDtitle = props.value.stock;
  var item_color1 = "#1FB31F";
  var item_colorText = "#000000";
  var item_colorback_tit = "#000000";
  var itemBenef = "+" + Number(props.value.Benf).toFixed(0) + props.value.m;
  var itemAcciones = props.value.acciones;

  if (props.value.rent < 0) {
    item_color1 = "#D61A18";
    itemBenef = Number(props.value.Benf).toFixed(0) + props.value.m;
  }

  if (props.grayScale) {
    item_color1 = "#CCCCCC";
    item_colorback_tit = "#CCCCCC";
    item_colorText = "gray.100";
  }

  if (props.hidetit) {
    itemAcciones = "";
    itemBenef = "";
  }

  return (
    <Wallet
      own_user={props.own_user}
      element={props.value}
      pos={props.pos}
      title={REVISEDtitle}
      subtitle={props.value.symbol}
      salida={props.value.sal + props.value.m}
      entrada={props.value.ent + props.value.m}
      acciones={itemAcciones}
      sentido={props.value.sentido}
      fent={props.value.fent}
      fsal={props.value.fsal}
      benef={itemBenef}
      rent={
        props.value.rent >= 0
          ? "+" + Number(props.value.rent).toFixed(2) + "%"
          : Number(props.value.rent).toFixed(2) + "%"
      }
      color1={item_color1}
      color2="#FFFFFF"
      colortext={item_colorText}
      colorback_tit={item_colorback_tit}
      mobile={props.mobile}
      isOptionsVisible={props.isOptionsVisible}
      toggleOptions={props.toggleOptions}
    />
  );
}

// Componente MyFavoritesList
function MyFavoritesList({ tt, grayScale, hidetit, mobile, ownuser }) {
  const [visibleItem, setVisibleItem] = useState(null); // Control del ítem visible
 
  /*
  const [histo, setHisto] = useState(getCurrentHistorial());

  useEffect(() => {
  
        setHisto(getCurrentHistorial());
}, []);
*/

  const toggleOptions = (pos) => {
    setVisibleItem(visibleItem === pos ? null : pos); // Alternar opciones visibles
  };

  var GRAY_SCALE = grayScale || false;
  var HIDE_TITBENEF = hidetit || false;
  var MOBILE_VERSION = mobile || false;

  //var toptrader = tt;

  var toptrader = glovar.SHOW_MY_PROFILE;


  //var CARTERA_INFO_HISTORIAL_TEXT = '[' + toptrader.historial + ']';
 // var CARTERA_INFO_HISTORIAL = JSON.parse(CARTERA_INFO_HISTORIAL_TEXT);

  var CARTERA_INFO_HISTORIAL_TEXT;
  var CARTERA_INFO_HISTORIAL;
  
  if(ownuser==1) {
    //CARTERA_INFO_HISTORIAL = glovar.CURRENT_HISTORIAL;
    CARTERA_INFO_HISTORIAL = glovar.CURRENT_HISTORIAL;
    
  }
  else {
    
    //CARTERA_INFO_HISTORIAL_TEXT = '[' + toptrader.historial + ']';
    CARTERA_INFO_HISTORIAL_TEXT = '[' + TOP_TRADER.historial + ']';
    CARTERA_INFO_HISTORIAL = JSON.parse(CARTERA_INFO_HISTORIAL_TEXT);
  }
 

  if (!CARTERA_INFO_HISTORIAL) return null;


  //console.log("MYFavoritesList glovar.SHOW_MY_PROFILE:"+JSON.stringify(glovar.SHOW_MY_PROFILE))
  //console.log("MYFavoritesList glovar.CURRENT_HISTORIAL:"+JSON.stringify(glovar.CURRENT_HISTORIAL))
  //console.log("own_user:"+ownuser)

  return (
    CARTERA_INFO_HISTORIAL.map((item, index) => (
      <Item
        key={item.id}
        value={item}
        pos={index + 1}
        grayScale={GRAY_SCALE}
        mobile={MOBILE_VERSION}
        hidetit={HIDE_TITBENEF}
        own_user={ownuser}
        isOptionsVisible={visibleItem === index + 1} // Determinar si mostrar opciones
        toggleOptions={toggleOptions}
      />
    ))
  );
}

// Componente principal
export default function TrackRecordTopTraderList({ toptrader, grayScale, hidetitbenef, mobile, own_user }) {
  var GRAY_SCALE = grayScale || false;
  var HIDE_TITBENEF = hidetitbenef || false;
  var MOBILE_VERSION = mobile || false;
  
  TOP_TRADER=toptrader;
  //console.log("toptrader:"+JSON.stringify(TOP_TRADER))

  if(glovar.NO_GRAYSCALE_NOPUBLIC==1) 
    {
  GRAY_SCALE=false;
  }
  //console.log("EJECUTO TRACKRECORDTTLIST!!!!!!!!!!!!");
  //console.log("toptrader\n:"+JSON.stringify(toptrader.historial));
  //console.log("historial\n:"+JSON.stringify(mierda));
  return (
    <Box ml={4} mr={4} mt={2}>
      <Flex mb={3} textColor="gray.400" fontSize="sm">
        <Text>{i18n.t("activo")}</Text>
        <Spacer />
        <Spacer />
        <Spacer />
        {1==2&&<Spacer />}
        {!MOBILE_VERSION && <Text>{i18n.t("titulos")}</Text>}
        <Spacer />
        <Text>{i18n.t("benef_rent")}</Text>
        <Spacer />
        <Text>{i18n.t("entrada")}</Text>
        <Spacer />
        <Text mr={2}>{i18n.t("salida")}</Text>
      </Flex>
      <Stack>
        <div className="row container_trackrecord">
          <MyFavoritesList tt={toptrader} grayScale={GRAY_SCALE} hidetit={HIDE_TITBENEF} mobile={MOBILE_VERSION} ownuser={own_user}/>
        </div>
      </Stack>
    </Box>
  );
}
