
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import glovar from './glovar';


const Payment = () => {

    const handleCheckout = async () => {
        //const stripe = await stripePromise; // Asegúrate de que Stripe está cargado antes de usarlo

        var customEmail=" ";

        // Recuperar y usar los datos almacenados
        let prepay_user = JSON.parse(sessionStorage.getItem("prepay_user"));

        if(glovar.MODE_USER>=1) {
            customEmail=prepay_user.user_email;
        }

        //console.log(storedCart.item1); // "Product A"
        //console.log(storedCart.item2); // "Product B"

        const response = await fetch(process.env.REACT_APP_URL_CREATE_CHECKOUT_SESSION, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...(glovar.MODE_USER >= 1 && { customerEmail: customEmail }), // Añadir customerEmail si corresponde
                premiumMode: prepay_user.premiumMode ? prepay_user.premiumMode : "M0"}),
            /*body: JSON.stringify(
                { customerEmail: customEmail,//'cliente@ejemplo.com', 
                  premiumMode: (prepay_user.premiumMode ? prepay_user.premiumMode : "M0")}), // Puedes reemplazar el correo con datos dinámicos
                  */
        });

        const data = await response.json();

        //guardo data.id que corresponde a la session_ID
        if(data.id) {
            // Agregar nuevos elementos al carrito
            prepay_user.session_id = data.id;
            sessionStorage.setItem("prepay_user", JSON.stringify(prepay_user));
        }


        if (data.url) {
            // Redirigir al usuario a Stripe Checkout
            window.location.href = data.url;
            
            prepay_user.url_checkout = data.url;
            sessionStorage.setItem("prepay_user", JSON.stringify(prepay_user));     
        } else {
            //console.error('Error al crear la sesión de pago:', data.error);
        }
    };

     // Ejecutar automáticamente cuando el componente se monte
     useEffect(() => {
        handleCheckout();
    }, []); // El array vacío asegura que se ejecuta solo una vez, al montar el componente

    return null; // No necesitas un botón si todo se maneja automáticamente

    /*
    return (
        <button onClick={handleCheckout}>
            Suscribirse
        </button>
    );*/
};

export default Payment;
