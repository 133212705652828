import React, { useEffect, useState } from 'react';
import { Box, Button, Text, VStack,Image } from '@chakra-ui/react';

import { 
    addSubsSERVER
     } from './../../utils/Common';

     import glovar from './glovar';

     import { setModeUser } from './../../utils/Common';

     const ThankYouPage = () => {
        return (
          <Box minH="100vh" display="flex" flexDirection="column">
            {/* Top half: Black background, white text */}
            <Box
              flex="1"
              bg="black"
              color="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >

 
              <VStack spacing={4}>
              <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall1.png`} h="100px" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          //try { sendEventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/home') 
        }} />
                <Text fontSize="2xl" fontWeight="bold" marginTop={20}>
                  Thank you for upgrading to Pro1X PREMIUM!
                </Text>
                <Text fontSize="lg">
                  We are thrilled to have you on board.
                </Text>
              </VStack>
            </Box>
      
            {/* Bottom half: White background, black button */}
            <Box
              flex="1"
              bg="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                colorScheme="blackAlpha"
                bg="black"
                color="white"
                size="lg"
                onClick={() => {
                  // Replace with the action to start enjoying the service
                  //console.log('Redirect to Pro1X PREMIUM dashboard');
                  window.location.replace('/home')
                }}
              >
                Start enjoying Pro1X PREMIUM
              </Button>
            </Box>
          </Box>
        );
      };

      const ThankRegisterPage = () => {
        return (
          <Box minH="90vh" display="flex" flexDirection="column">
            {/* Top half: Black background, white text */}
            <Box
              flex="1"
              bg="black"
              color="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >

 
              <VStack spacing={2}>
              <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall1.png`} h="100px" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          //try { sendEventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/home') 
        }} />
                <VStack maxWidth="60%">
                <Text fontSize="2xl" fontWeight="bold" marginTop={10}>
                To enjoy Pro1X PREMIUM, you must create your Pro1X account using the same email address you used during the payment process.
                </Text>
                <Text fontSize="lg"  paddingTop={4}>
                Once registered, your PREMIUM account will activate automatically. For any issues, contact us at contact@pro1x.com with the subject (Pro1X PREMIUM not registered) and the email used for payment.
                </Text>
                </VStack>
              </VStack>
            </Box>
      
            {/* Bottom half: White background, black button */}
            <Box
              flex="1"
              bg="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                colorScheme="blackAlpha"
                bg="black"
                color="white"
                size="lg"
                onClick={() => {
                  // Replace with the action to start enjoying the service
                  //console.log('Redirect to Pro1X PREMIUM dashboard');
                  window.location.replace('/register')
                }}
              >
                Create your Pro1X account
              </Button>
            </Box>
          </Box>
        );
      };



const Completion = () => {
    const [paymentStatus, setPaymentStatus] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get('session_id'); // Obtén el session_id de la URL

        if (sessionId) {
             // Recuperar y usar los datos almacenados
             let prepay_user = JSON.parse(sessionStorage.getItem("prepay_user"));
             //console.log("recupero prepay_user:"+JSON.stringify(prepay_user));

            const checkPayment = async () => {
                const response = await fetch(process.env.REACT_APP_URL_CHECK_PAYMENT_STATUS, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    //body: JSON.stringify({ sessionId }),
                    body: JSON.stringify({ sessionId, prepay_user}), // Puedes reemplazar el correo con datos dinámicos
                });

                const predata = await response.json();
                const data=predata.session;
                //let mutabledata = { ...data };

                //console.log(JSON.stringify(data))
                 // Recuperar y usar los datos almacenados
                //let prepay_user = JSON.parse(sessionStorage.getItem("prepay_user"));

                //Procesamos la transacción correctamente.
                if(data.payment_status=='paid') {
                    
                    if(prepay_user.mode_user==0) {
                        //Página Complete especial donde se le indicará que envie un email a contact@pro1x.com indicando que ha realizado una suscripción
                         //en la modalidad que toque y que ha indicado como email en el pago el email blablabla@bla.com
                        // Guardar datos en localStorage
                        localStorage.setItem("premium_pending", JSON.stringify({ prepay_user: prepay_user, session: data }));
                        setPaymentStatus(data.payment_status+"_0"); 

                    }
                    else if(prepay_user.mode_user>=1) {
                        //Actualtizem a firebase/mongo mode=2 y los timestamp
                        //Pro1XBACKEND
                        // /api/updateUser o /api/updatesuserbody
                        //addSubsSERVER(prepay_user,mutabledata);
                        glovar.MODE_USER=2;
                        glovar.MODE_USER_WEB=2;
                        setModeUser(glovar.MODE_USER);
                        //console.log("ACTUALIZO A PREMIUM AL USER: "+glovar.USER_USERNAME)
                        setPaymentStatus(data.payment_status+"_1"); 
                    }
                }
                else {
                 setPaymentStatus(data.payment_status); // Actualiza el estado del pago
                }
            };

            checkPayment();
        }
    }, []);

    return (
        <div>
            {paymentStatus === 'paid_1' ? (
                <ThankYouPage/>
             ) : paymentStatus === 'paid_0' ? (
                <ThankRegisterPage/>
            
            ) : paymentStatus === 'unpaid' ? (
                <h1>ERROR: The payment was not processed correctly. Please try again."</h1>
            ) : (
                <h1>Checking the payment status...</h1>
            )}
        </div>
    );
};

export default Completion;