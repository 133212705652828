

import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack,VStack,Flex } from "@chakra-ui/layout"; 
import { Image } from "@chakra-ui/image";
import glovar from './glovar';
import { useToast } from '@chakra-ui/react'

import i18n from '../../i18n';
import ReactGA from 'react-ga4';
import React, { useState } from 'react';
import eventBus from "../eventBus";

import { 
  isValorFAV,addRemoveFAV,addPortfolioVALOR
   } from '../../utils/Common';

//import buttonApple from `${process.env.PUBLIC_URL}/assets/btnapple.png`


//import { FiArrowDown, FiArrowUp } from "react-icons/fi";

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }
}



function shareProfile() {
  //window.open(glovar.HOME_URL+"/"+glovar.USER_USERNAME,"_self")
  navigator.clipboard.writeText(glovar.HOME_URL+"/"+glovar.USER_USERNAME) 
}

function shareSymbol(symbol) {
  //window.open(glovar.HOME_URL+"/"+glovar.USER_USERNAME,"_self")
  navigator.clipboard.writeText(glovar.HOME_URL+"/symbol/"+symbol) 
}

function btndownloadApp(app) 
{

  var DEFAULT_LANG="EN"

  try {  DEFAULT_LANG=getNavigatorLanguage().split("-")[0]; } catch (exxx) {}

  if(app==1)  { 
    window.open(glovar.URL_APPSTORE,"_self")
    try { ReactGA.event("btn_download_IOS"); } catch(Exxx) {}
    try { ReactGA.event("btndwload_IOS_"+DEFAULT_LANG); } catch(Exxx) {}
  }
  if(app==2)  {
    window.open(glovar.URL_PLAYSTORE,"_self")
    try { ReactGA.event("btn_download_ANDROID"); } catch(Exxx) {}
    try { ReactGA.event("btndwnload_AND_"+DEFAULT_LANG); } catch(Exxx) {}
    //try { ReactGA.pageview("")}
  }
 }

 function openlinkurl() {

  var DEFAULT_LANG="EN";

  var LINK_URL_SUSCRIPTION=glovar.LINK_URL_TIPOS_SUSCRIPCION_EN;
  try {
    const currentLocale=getNavigatorLanguage().split("-")[0];
    if(currentLocale.includes("es")) 
    {
      LINK_URL_SUSCRIPTION=glovar.LINK_URL_TIPOS_SUSCRIPCION_ES;
      DEFAULT_LANG="ES";
    }
    //console.log("NAVIGATOR LANGUAGE:"+getNavigatorLanguage().split("-")[0])
 
   }
   catch(Exxx) {}

   window.location.replace('/premium')
  //window.open(LINK_URL_SUSCRIPTION,"_self")

  try { ReactGA.event("btn_PRO1PRWEB_"+DEFAULT_LANG); } catch(Exxx) {}
 }
 

 
        
 var showShareOptions=false;
 var showFAVasset=false;
 var MOBILE_VERSION=0;
 var VALOR_FAVORITO=false;
 var VALOR=null;

  export default ({ mode, mobile, name, symbol, valor/*,fav*/ }) => {

    if(mobile==1) {
      MOBILE_VERSION=true;
    }

    VALOR_FAVORITO=isValorFAV(symbol);
    if(valor) {
     VALOR=valor;
    }


    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [showPopLogin,setshowPopLogin] = useState(false);

    //console.log("render");

    const toast = useToast()

    var withicons="11%"
    if(mode==7) 
    {
      return (
      <HStack marginTop={-1} flexDirection="row" justifyContent="flex-end" marginRight={0} marginLeft={0}>
      
      {showPopLogin&&
        <Box zIndex={3} background="red"position="absolute"
  >
          <div className='popup_login'>
          <div className='popup_inner_mobile_login'>
          <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={2} pb={20} alignContent="center">
          <VStack marginTop="1pc">
        
       <HStack>   
      <Image src={`${process.env.PUBLIC_URL}/assets/baseline_add_box_black_48.png`} w={14} p={0}  marginTop={0} />
      <Image src={`${process.env.PUBLIC_URL}/assets/baseline_favorite_border_black_48.png`} w={14} p={0}  marginTop={0} />
      </HStack>
      <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={10} pl={20} pr={20} pt={0} textColor="gray.800">
        {i18n.t("add_desde_ficha")}</Text>
        <HStack marginTop="140px">
        {1==2&&<Image _hover={{ cursor: "pointer" }} src='assets/btnapple.png'p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />}
        {1==2&&<Image _hover={{ cursor: "pointer" }} src='assets/btnandroid.png'p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />}
        {!glovar.USER_LOADED&&<Box  marginTop={10}>
              <button className="formLogInButtonContent"  _hover={{ cursor: "pointer" }} 
            onClick= {() =>  {
              try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/login') 
            }}
            style={{  marginRight:"1pc", width: "10pc",  textAlign:"center", fontWeight:600 }}>
            {i18n.t("login")}</button>         
            <button className="formSignInButtonContent"  _hover={{ cursor: "pointer" }} 
           onClick= {() => {
            if(glovar.REGISTER_ONLINE==true) 
            {
            try { //EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline");
           } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { //EventGA("button_sup","pressbtn_register", "pressbtn_register"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            } }
            }
            style={{ width: "10pc",  textAlign:"center"}}>
            {i18n.t("signup")}</button>          
            </Box>}
  
        
        </HStack>
     
      </VStack>   
           <Text  as='u' textAlign="center" paddingTop="10px" fontSize="12" textColor="black" fontFamily="Tahoma" 
           _hover={{ cursor: "pointer" }} fontWeight="normal" 
           onClick= {() =>
            {
   
             //console.log("buscar!!")
             setshowPopLogin(false)
            }
            }> 
            {i18n.t("no_gracias_gratis")}
            </Text>
  
           </VStack>
          </div>
        </div>
        </Box>}

      {showShareOptions&&<HStack>
    
      <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_whatsapp_black_24dp.png`}
           width={withicons}      onClick={() => 
        {
          window.open('https://web.whatsapp.com/send?text=¡Hola! Te envío la ficha técnica completa de '+name+' en \n https://pro1x.com/symbol/'+symbol,"_blank")
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_twitter_black_36dp.png`}
             width={withicons}  onClick={() => 
        {
          window.open('http://twitter.com/share?text=Ficha técnica completa de '+symbol+" en @Pro1trading \n+"+'&url=https://pro1x.com/symbol/'+symbol/*+'&hashtags=pro1trading,stocks,portfolio'*/,"_blank")
        
        }} />

        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_linkedin_black_36dp.png`}
              width={withicons}   onClick={() => 
        {
          window.open('https://www.linkedin.com/shareArticle?mini=true&url=https://pro1x.com/symbol/'+symbol+
          '&title=Pro1X Portfolio&summary=Ficha técnica completa de '+name+' en Pro1X &source=https://pro1x.com/symbol/'+symbol,"_blank")
        }} />

        {(1==2)&&<Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_instagram_black_24dp.png`}
              width={withicons}   onClick={() => 
        {
          
        }} />}
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_email_black_24dp.png`}
              width={withicons}   onClick={() => 
        {
          window.open('mailto:?subject=Ficha técnica de '+name+' en Pro1X&body=¡Hola! Te envío la ficha técnica de '+name+' en Pro1X: \n'+ 
          'https://pro1x.com/symbol/'+symbol+' \n')
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_link_black_24dp.png`} width={withicons}
        onClick={() => 
          {
            shareSymbol(symbol);
           //shareProfile();
           toast({
             position: 'bottom-left',
             render: () => (
            <Box color='white' p={3} bg='blue.500'>
              URL copiada en el portapapeles
            </Box>
           ),
          })
        
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_share_variant_black_24dp.png`}
      width={withicons} 
        onClick={() => 
        {
          showShareOptions=!showShareOptions;
          //console.log("he pulsaaaaaaaaaa")
          forceUpdate()
         }
        } />
        </HStack>}


        {!showShareOptions&&
        <Box  onClick={() => 
          {
            if(glovar.MODE_USER==0) { 
              setshowPopLogin(true);
            }
            else {
              //addPortfolioVALOR(VALOR);
              glovar.CURRENT_MENU_ID=3;
              eventBus.dispatch("showAddPosPortfolio", { pos: 1, valor:VALOR });
            }
            //showShareOptions=!showShareOptions;
            //console.log("he pulsaaaaaaaaaa")
            //forceUpdate()
           }}>
            <Image  _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/baseline_add_box_black_48.png`}  w={9} p={0}  marginTop={0} />
        </Box>
        }
        {!showShareOptions&&
        <Box  onClick={() => 
          {
            if(glovar.MODE_USER==0) { 
              setshowPopLogin(true);
            }
            else {
              //showFAVasset=!showFAVasset;
              VALOR_FAVORITO=!VALOR_FAVORITO;
              //console.log("VALOR:"+VALOR+" VALOR FAV:"+VALOR_FAVORITO);
              addRemoveFAV(VALOR,VALOR_FAVORITO);
              
            //console.log("he pulsaaaaaaaaaa")
            forceUpdate()
            }
           }}>
            {!VALOR_FAVORITO&&<Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/baseline_favorite_border_black_48.png`}  w={8} p={0}  marginTop={0} />}
            {VALOR_FAVORITO&&<Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/baseline_favorite_black_48.png`}  w={8} p={0}  marginTop={0} />}
        </Box>
        }

        {!showShareOptions&&
        <HStack onClick={() => 
          {
            showShareOptions=!showShareOptions;
            //console.log("he pulsaaaaaaaaaa")
            forceUpdate()
           }
          }>
          <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_share_variant_black_24dp.png`}
          w={7} marginRight={6} /></HStack>}
      </HStack>);
    }
    
    if(mode==6) 
    {
      return (
      <HStack marginTop={0} flexDirection="row" /*justifyContent="flex-end"*/  marginRight={0} marginLeft={3} >
      
      {showShareOptions&&<HStack  flexDirection="row" /*justifyContent="flex-end"*/ marginRight="-1%">
    
      <Image _hover={{ cursor: "pointer" }}  src={`${process.env.PUBLIC_URL}/assets/ic_whatsapp_black_24dp.png`} 
              width="10%"   onClick={() => 
        {
          window.open('https://web.whatsapp.com/send?text=¡Hola! Te envío mi portfolio de valores e historial en Pro1X\n https://pro1x.com/'+glovar.USER_USERNAME,"_blank")
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_twitter_black_36dp.png`} 
             width="10%"  onClick={() => 
        {
          window.open('http://twitter.com/share?text=Este es mi portfolio de valores e historial en @pro1xcom+'+"\n+"+'&url=https://pro1x.com/'+glovar.USER_USERNAME/*+'&hashtags=pro1trading,stocks,portfolio'*/,"_blank")
        }} />

        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_linkedin_black_36dp.png`} 
              width="10%"   onClick={() => 
        {
          window.open('https://www.linkedin.com/shareArticle?mini=true&url=https://pro1x.com/'+glovar.USER_USERNAME+
          '&title=Pro1X Portfolio&summary=Este es mi portfolio de valores e historial en Pro1X&source=https://pro1x.com/'+glovar.USER_USERNAME,"_blank")
        }} />

        {(1==2)&&<Image _hover={{ cursor: "pointer" }} src='assets/ic_instagram_black_24dp.png'
              width="10%"   onClick={() => 
        {
          
        }} />}
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_email_black_24dp.png`} 
              width="10%"   onClick={() => 
        {
          window.open('mailto:?subject=Mi portfolio en Pro1X&body=¡Hola! Te envío mi portfolio de valores e historial en Pro1X: \n'+ 
          'https://pro1x.com/'+glovar.USER_USERNAME)
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_link_black_24dp.png`}  width="10%"  
        onClick={() => 
          {
           shareProfile();
           toast({
             position: 'bottom-left',
             render: () => (
            <Box color='white' p={3} bg='blue.500'>
              URL copiada en el portapapeles
            </Box>
           ),
          })
        
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_share_variant_black_24dp.png`}
        onClick={() => 
        {
          showShareOptions=!showShareOptions;
          //console.log("he pulsaaaaaaaaaa")
          forceUpdate()
         }
        } />
        </HStack>}


        {!showShareOptions&&<Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_share_variant_black_24dp.png`}
      width="19%"  
        onClick={() => 
        {
          showShareOptions=!showShareOptions;
          //console.log("he pulsaaaaaaaaaa")
          forceUpdate()
         }
        } />}
      </HStack>);
    }
    if(mode==5) {
      return(null);
      /*
      if(MOBILE_VERSION) 
      {
        return (
          <VStack>
          <Text  fontSize="m" fontWeight="normal" textAlign="center" p={1} mb={0} ml={2} mr={2} pt={2} textColor="gray.800">
            {i18n.t("webapp_only_desktop")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
            <HStack marginTop={4} paddingBottom={2}>
            <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnapple.png`} p={0}  width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
            <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnandroid.png`} p={0}  width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
            </HStack>     
          </VStack>
        );
      }
      else {
      return (
        <VStack>
        <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={0} ml={8} mr={8} pt={4} textColor="gray.800">
          {i18n.t("webapp_only_desktop")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
          <HStack marginTop={4}>
          <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnapple.png`} p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
          <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnandroid.png`} p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
          </HStack>     
        </VStack>
      );
      }*/
    }
    if (mode==4) {
      return(null);
      /*
      return (
      <VStack align="center">
        
      <Image src={`${process.env.PUBLIC_URL}/assets/logopro1trading.png`} pl={20} pr={20} pt={10} pb={10}  marginTop={0} />
      
      
      <Text  fontSize="xl" fontWeight="normal" textAlign="center" p={0} mb={0} ml={0} pt={0} textColor="gray.800">
        {i18n.t("webapp_only_desktop")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
        <VStack marginTop={12}>
        <Image _hover={{ cursor: "pointer" }} pt={5} src={`${process.env.PUBLIC_URL}/assets/btnapple.png`} onClick={() => btndownloadApp(1)} />
        <Image _hover={{ cursor: "pointer" }} pt={2} src={`${process.env.PUBLIC_URL}/assets/btnandroid.png`} onClick={() => btndownloadApp(2)}  />
        </VStack>
     
        

      </VStack>
       );
       */
    }
    if(mode==3) {

      var classButtton="formField";
      if(mobile) {
        classButtton="mobformField"; //Button";
      }

      //return(null);

      
      return (
      <VStack marginTop="2pc">
        
       
       
      {mobile&&<Text  fontSize="14" fontWeight="normal" textAlign="center" p={0} mb={3} ml={0} pt={0}  textColor="gray.800">
      {i18n.t("descript_pro1premiumweb")}</Text>}

      {!mobile&&<Text  fontSize="m" fontWeight="semibold" textAlign="center" p={0} mb={3} ml={0} pt={0}  textColor="gray.600">
      {i18n.t("descript_pro1premiumweb")}</Text>}
      
        
        <div onClick={() =>openlinkurl()}>
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/candadopro1x.png`} p={0} width="6pc" mb={3}   />
        </div>
     
        <div className={classButtton} onClick={() =>openlinkurl()}>
          <button className="formFieldButton">{i18n.t("quiero_pro1premiumweb")}</button>{" "}
          
        </div>

      </VStack>
 
    );
    
    }


    if(mode==2) {
      //return(null);
      
      //<Image src='assets/baseline_favorite_black_48.png'w={12} p={0}  marginTop={-2} />
       return (
        <VStack marginTop={glovar.USER_LOADED ? 20 : "1pc"}>
        
      <Image src={`${process.env.PUBLIC_URL}/assets/baseline_add_box_black_48.png`} w={14} p={0}  marginTop={-2} />
      
      <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={10} pl={14} pr={14} pt={0} textColor="gray.800">
        {i18n.t("add_activo_portfolio_ficha")}</Text>
        <HStack marginTop="140px">
        {1==2&&<Image _hover={{ cursor: "pointer" }} src='assets/btnapple.png'p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />}
        {1==2&&<Image _hover={{ cursor: "pointer" }} src='assets/btnandroid.png'p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />}
        {!glovar.USER_LOADED&&<Box  marginTop={10}>
              <button className="formLogInButtonContent"  _hover={{ cursor: "pointer" }} 
            onClick= {() =>  {
              try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/login') 
            }}
            style={{  marginRight:"1pc", width: "10pc",  textAlign:"center", fontWeight:600 }}>
            {i18n.t("login")}</button>         
            <button className="formSignInButtonContent"  _hover={{ cursor: "pointer" }} 
           onClick= {() => {
            if(glovar.REGISTER_ONLINE==true) 
            {
            try { //EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline");
           } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { //EventGA("button_sup","pressbtn_register", "pressbtn_register"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            } }
            }
            style={{ width: "10pc",  textAlign:"center"}}>
            {i18n.t("signup")}</button>          
            </Box>}
  
        
        </HStack>
     
      </VStack> 
    );

      /*return (
      <VStack marginTop="1pc">
        
      <Image src='assets/outline_add_circle.png'p={0}  marginTop={-2} />
      
      <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={10} ml={0} pt={0} textColor="gray.800">
        {i18n.t("add_activo_cartera_app")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
        <HStack marginTop="140px">
        <Image _hover={{ cursor: "pointer" }} src='assets/btnapple.png'p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
        <Image _hover={{ cursor: "pointer" }} src='assets/btnandroid.png'p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
        </HStack>
     
      </VStack> 
    );*/
    
    }

    else {
      //return(null);
      

    return (
        
    
      <VStack marginTop={glovar.USER_LOADED ? 10 : 0}>

      <Image src={`${process.env.PUBLIC_URL}/assets/baseline_favorite_black_48.png`} w={12} p={0}  marginTop={0} />
       
      
      <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={0} ml={0} pt={0} textColor="gray.800">
        {i18n.t("add_activo_desde_ficha")}</Text>
        <HStack marginTop={4}>
        {1==2&&<Image _hover={{ cursor: "pointer" }} src='assets/btnapple.png'p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />}
        {1==2&&<Image _hover={{ cursor: "pointer" }} src='assets/btnandroid.png'p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />}
        
        {!glovar.USER_LOADED&&<Box  marginTop={10}>
              <button className="formLogInButtonContent"  _hover={{ cursor: "pointer" }} 
            onClick= {() =>  {
              try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/login') 
            }}
            style={{  marginRight:"1pc", width: "10pc",  textAlign:"center", fontWeight:600 }}>
            {i18n.t("login")}</button>         
            <button className="formSignInButtonContent"  _hover={{ cursor: "pointer" }} 
           onClick= {() => {
            if(glovar.REGISTER_ONLINE==true) 
            {
            try { //EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline");
           } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { //EventGA("button_sup","pressbtn_register", "pressbtn_register"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            } }
            }
            style={{ width: "10pc",  textAlign:"center"}}>
            {i18n.t("signup")}</button>          
            </Box>}

        </HStack>
     
      </VStack>
 
    );
    
    }
  };
  